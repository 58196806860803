@import "../../scss/vars.scss";

.details-item-modal {
  width: 30%;
  min-width: 400px;
  padding: 0;

  .ant-modal-content {
    border-radius: $border-radius;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    display: none;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;

    img {
      border-radius: $border-radius $border-radius 0 0;
      height: 100%;
      object-fit: cover;
      max-height: 250px;
    }

    .data {
      padding: $padding;

      h3 {
        font-weight: 700;
        font-size: $text-size-lg;
        line-height: 1.25em;
        margin: 0;
      }

      section {
        font-size: $text-size;
        padding: $padding-sm 0;
        line-height: 1.25em;
        margin: 0;

        &.empty-name {
          padding: 0;
        }
      }

      button.ant-btn {
        background: $color-premium;
        border-color: $color-premium;
        color: #fff;
      }
    }

    p.new-lines {
      white-space: pre-line;
    }
  }
}

@media (max-width: 1024px) {
  .details-item-modal {
    width: 50%;
    min-width: 50%;
  }
}

@media (max-width: 750px) {
  .details-item-modal {
    width: 100% !important;
    max-width: 100% !important;
    top: 0;
    margin: 0;

    .ant-modal-content {
      height: 100vh;
      border-radius: 0;
    }

    .content {
      img {
        border-radius: 0;
        height: 40vh;
      }

      .data {
        height: 60vh;
        overflow: auto;
      }
    }

    .ant-modal-close-x {
      top: 12px;
      right: 12px;
    }
  }
}

