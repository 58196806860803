@import '../../scss/vars.scss';
.biz-social {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 $padding;

  li {
    a,
    span.lk{
      display: flex;
      padding: 0 10px;
    }
  }

  svg, img {
    width: 1.8em;
    height: 1.8em;
    fill: $color-text;
  }
}
