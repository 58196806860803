@import "../../../scss/vars.scss";

.edit-location-modal {
  .ant-modal {
    top: 50px;

    .ant-modal-content {
      width: 600px !important;
      height: 641px;
      align-items: center;
      max-width: 100%;
      padding: 30px 60px !important;
    }
  }

  .ant-modal-header {
    text-align: center;
    padding: 0;
    border-bottom: none;
  }

  .ant-modal-title {
    font-weight: bold;
    font-size: $text-size-lg + 2;
  }

  .ant-modal-body {
    width: 100%;

    header {
      font-size: 15px;
      margin: 3px -20px 0;
    }

    section {
      margin-top: 10px;

      &:first-child, &:nth-child(2) {
        margin-top: 20px;
      }

      div.section-header {
        text-align: start;
        font-size: 15px;
        line-height: 21px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      .split {
        text-align: left;

        input.err {
          border-color: $err-color;
          color: $err-color;
        }

        .half {
          &:first-child {
            margin-right: 10px;
          }

          &.right {
            .ant-form-item-control {
              align-items: flex-end;
            }
          }

          .ant-form-item {
            margin-bottom: 10px;
          }

          div.ant-select {
            width: 100%;

            .ant-select-selector {
              .ant-select-selection-item {
                text-align: start;
              }
            }

            .ant-select-arrow {
              svg {
                font-size: $text-size-xs;
              }
            }
          }
        }
      }

      .ant-form-item-control {
        .ant-btn {
          line-height: 36px;
          width: 93px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .edit-location-modal {
    .ant-modal {
      margin: auto;
      .split {
        display: grid;
        grid-template-columns: auto;

        .half:first-child {
          margin-right: 0;
        }
      }

      .footer-mobile {
        grid-template-columns: auto auto!important;
        grid-column-gap: $grid-column-gap;
      }
    }
  }
}
