@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.site-link {
  display: none;
}

.edit-restaurant {
  position: relative;
  max-width: calc(100% - 744px);
  margin: 0 320px;

  footer.actions {
    padding: 0 0 $padding-xs;
    text-align: right;
  }

  .ant-card {
    margin-bottom: $padding;
  }

  .split {
    width: 100%;

    &>div {
      flex: 1;
      align-self: flex-start;
      &:first-child {
        margin-right: $padding-sm;
      }
      &:last-child {
        margin-left: $padding-sm;
      }
    }
  }

  .ant-select {
    width: 100%;
  }

  label {
    font-weight: 500;
    color: $color-text;
  }

  .ant-tabs.enhanced.fixed {
    .ant-tabs-nav {
      width: $dashboard-secondary-sidebar-width;

      .ant-tabs-nav-list {
        margin-top: 12px;

        .ant-tabs-tab {
          margin: 8px auto 0;
          width: 260px;
          height: 38px;
          border-radius: 6px;
    
          .ant-tabs-tab-btn {
            display: flex;
            gap: 12px;
            font-size: 15px;
    
            .anticon {
              margin: 0;
              width: 22px;
              height: 22px;
    
              svg {
                width: 100%;
                height: 100%;
                color: $color-text;
              }
            }
          }
    
          &.ant-tabs-tab-active {
            background-color: $color-btn-light-grey;
    
            .ant-tabs-tab-btn {
              border: none;
              color: $color-text;
              font-weight: bold;
            }
          }
    
          &:hover {
            color: $color-text;
            background-color: $color-btn-light-grey;
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }
    }

    .ant-tabs-content {
      width: 100%;

      .ant-card {
        border: none;
        border-radius: 0;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &:first-child {
          border-bottom: 1px solid $color-btn-border-grey;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .site-link {
    @include position(sticky);
    background: white;
    border-bottom: $border;
    display: flex;
    justify-content: center;
    padding: $padding;
    top: -1px;
    z-index: 2;
    margin-left: 180px;

    h6 {
      font-size: $text-size;
      font-weight: 700;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 15px;

      a {
        color: $color-link;
        font-weight: 400;
        text-decoration: underline;
        white-space: nowrap;

        &:hover {
          color: $color-link;
          opacity: .75;
        }
      }
    }
  }
  .edit-restaurant {
    max-width: 100%;
    margin: 0;

    .ant-tabs {
      & > .ant-tabs-nav {
        background-color: white;
        z-index: 12;
      }
    }
  }
}

@media (max-width: 768px) {
  .site-link {
    @include position(sticky);
    background: white;
    border-bottom: $border;
    display: flex;
    justify-content: center;
    padding: $padding;
    padding-bottom: 20px;
    top: -1px;
    z-index: 2;
    margin-left: 0;

    h6 {
      font-size: $text-size;
      font-weight: 700;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        color: $color-text;
        font-weight: 400;
        text-decoration: underline;
        white-space: nowrap;
        margin-top: 4px;
        color: $color-link;

        &:hover {
          color: $color-link;
          opacity: .75;
        }
      }
    }
  }

  .edit-restaurant {
    .split {
      flex-direction: column;

      & > div {
        width: 100%;
        margin: 0 !important;
      }
    }

    .ant-tabs {
      & > .ant-tabs-nav {
        display: none;
      }

      & > .ant-tabs-content-holder {
        margin: 0 !important;
      }
    }
  }
}
