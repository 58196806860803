@import '../../scss/vars.scss';
@import '../../scss/mixins.scss';

.menu-nav {
  overflow: hidden;
  height: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.sticky {
    @include position(sticky);
    top: -1px;
  }

  .scroller {
    overflow: auto;
  }

  ul {
    display: flex;

    &.center {
      align-items: center;
      justify-content: center;
    }

    .ant-btn {
      padding-top: $padding-xs + 2px;
      padding-bottom: $padding-xs;
      height: auto;
      font-size: 16px;
    }
  }

  .scr {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 4px;
    background: rgba(255, 255, 255, .9);
    border-radius: 50%;
    z-index: 1;
    box-shadow: $box-shadow;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 15px;
    }

    &.l {
      left: 4px;
    }

    &.r {
      right: 4px;
    }
  }
}
