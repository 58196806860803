@import '../../scss/vars.scss';

$height: 120px;
html {
  --lh: 1.4rem;
  line-height: var(--lh);
}

.static-menu-line {
  padding-bottom: $padding-sm;
  height: auto;
  display: grid;
  grid-template-columns: auto 110px;
  align-items: center;
  border-bottom: $border;
  padding: 15px 0;
  min-height: 130px;

  &:last-child {
    margin: 0;
    padding: 0;
  }

  .preview {
    width: 100%;
    max-height: 100px;
    background-size: cover;
    background-position: center center;
    min-height: 100px;
    border-radius: 6px;
  }
  .description .price {
    margin-bottom: 2px;
  }


  .content {
    overflow: hidden;
    white-space: pre-line;
    
    &.two-lines {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.one-line {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      &:empty {
        display: none;
      }
    }

    &.four-lines {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }

  h3 {
    margin: 0 !important;
    line-height: 1.25em !important;
    font-weight: 800;
    font-size: 16px !important;

    &+ul {
      li {
        img {
          width: 1.25em;
          height: 1.25em;
          vertical-align: baseline;
        }
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 1.25em;
    margin: 0;
  }

  .data {
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
  }
}

.cursor-not-preview {
  cursor: pointer;
}

.one-column {
  grid-template-columns: auto;
}
