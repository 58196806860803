@import 'src/scss/vars';

.pointer-saturation {
  cursor: crosshair;
  border: solid 1px black;
  border-radius: 10px;
  background: none;
  margin-top: -6px;
  margin-left: -6px;

  div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: solid 2px white;
    box-sizing: content-box;
  }
}
