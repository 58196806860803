@import '../../../scss/vars.scss';


.container {
  > section > div {
    margin-top: $padding-sm;
  }

  footer {
    display: flex;
    justify-content: center;
    margin-top: 100px;

    button:last-child {
      margin-left: $padding-xs;
    }
  }
}

.name {
  display: flex;

  div:first-child {
    flex: 2;
    display: flex;
    align-items: center;
  }

  div:last-child {
    flex: 8
  }
}

.selection {
  display: flex;

  div {
    flex: 7;

    &:first-child {
      flex: 2
    }

    &:last-child {
      flex: 1;
      display: inline-flex;
      align-items: center;

      label {
        padding-right: 5px;
      }
    }
  }
}

.options {
  > * {
    margin-top: $padding-sm;
  }
}

.optionsLine {
  display: flex;

  div {
    flex: 1;

    > input {
      width: 90%;
    }
  }
}

.price {
  display: flex;

  > * {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;

    > svg {
      font-size: $delete-icon-size;
    }
  }

  > div {
    flex: 9;
  }
}

.radioGroup {
  label {
    width: 50%;
    display: inline;
  }
}