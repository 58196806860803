@import '../../scss/vars.scss';

.biz-banner {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-height: $order-bar-height;
  font-size: $text-size-announcement;
  line-height: 1.25em;
  position: relative;
  padding: 12px 24px;

  span.close-icon {
    top: 4px !important;
    right: 4px !important;
    width: 26px;
    height: 26px;
    line-height: 29px;
    background: rgba(255, 255, 255, 0.6);

    &.preview {
      cursor: initial;
    }
  }
}
