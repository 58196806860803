@import '../../../scss/vars.scss';

.ant-modal:not(.details-item-modal):not(.business-hours-modal).menu-icon-modal {
  .ant-modal-content {
    .close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      .anticon {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
          color: $color-text;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .scroller {
      overflow: auto;
    }

    .icon-section-label {
      position: relative;
      text-align: left;
      color: $color-grey;
      margin: 20px 0 0 0;
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;

      .icon-actions {
        position: absolute;
        right: 0;
        top: -2px;
        display: flex;
        align-items: center;
        gap: 8px;

        .ant-btn {
          height: 20px;
          font-size: 11px;
          line-height: 11px;
          padding: 0 6px;
        }

        .delete-icon {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  
    ul {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
      padding: 0;
      list-style-type: none;
      margin: 10px 0;
  
      li {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ddd;
        background: $color-btn-light-grey;
        border-radius: 6px;
        width: 34px;
        height: 32px;
        margin-right: 6px;
        padding: 3px;
        cursor: pointer;
        
        img {
          width: 100%;
          height: 100%;
        }

        .edit-icon {
          position: absolute;
          top: 30px;
          margin-top: 3px;
          height: 17px;
          cursor: pointer;

          &:hover {
            svg {
              color: $color-primary;
            }
          }
        }

        &.upload-btn {
          border: none;

          >span, .ant-upload {
            width: 100%;
            height: 100%;

            span.ant-upload {
              display: block;
              font-size: 24px;
              line-height: 26px;
              color: $color-text;
            }
          }

          >span.anticon {
            width: 50%;
            height: 50%;

            svg {
              width: 100%;
              height: 100%;
            }
          }
        }

        &.active {
          border-color: $color-primary;
        }
  
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}