@import '../../scss/vars.scss';

.first-time-published-modal {
  .ant-image {
    margin-top: 10px;
  }
  
  h2 {
    line-height: 30px;
    margin: 15px 0 !important;
  }

  .ant-modal-footer {
    margin-bottom: 5px;

    .ant-btn:not(.ant-btn-primary) {
      display: none !important;
    }
  }
}
