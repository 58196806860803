@import '../../scss/vars.scss';

.order-menu {
  h3 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -.5px;
    color: $color-text;
  }

  .ant-skeleton {
    margin-top: $padding-xl;
  }
}

@media (max-width: 425px) {
  .order-menu {
    h1 {
      font-size: 40px;
      margin: $padding-xl 0 $padding-sm;
      text-align: center;
    }

    h2 {
      text-align: center;
      font-size: 22px;
      margin: 0 0 $padding-lg;
    }
  }
}
