@import "../../scss/vars.scss";

.url-change-confirm-modal {
  footer {
    margin-top: $padding;
    button:first-child {
      margin-right:$padding-xs;
    }
  }
}
