@import '../../../scss/vars.scss';

.sortable-menu-item-container {
  position: relative;

  svg + div {
    padding-left: $padding-lg;
  }
}

.drag-handle {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  fill: $color-grey;

  &.visible {
    visibility: visible;
  }

  &:hover {
    cursor: grab;
  }

  &.top-right {
    right: 0;
    margin-top: $padding-xs;
  }
  &.left-center {
    top: calc(50% - 8px);
  }
  &.left {
    margin-top: 9px;
    margin-left: 8px;
  }
  &.left-top {
    top: 65px;
  }

  &:focus {
    outline: none;
  }
}

.btn-link {
  margin-left: 10px;
}

.button-container {
  display: flex;
}
.unavailable * {
  color: $color-text-grey;
  fill: $color-text-grey;
}
