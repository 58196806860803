@import '../../scss/vars.scss';

.loader {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
.loader-div-center {
  margin-left: 43%;
}
.loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: $color-primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0)
}
.loader.small {
  zoom: .8;
  vertical-align: middle;
}
.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 .6s infinite
}
.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 .6s infinite
}
.loader div:nth-child(3) {
  left: 32px;
  animation: loader2 .6s infinite
}
.loader div:nth-child(4) {
  left: 56px;
  animation: loader3 .6s infinite
}
@keyframes loader1 {
  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1)
  }
}
@keyframes loader3 {
  0% {
    transform: scale(1)
  }
  100% {
    transform: scale(0)
  }
}
@keyframes loader2 {
  0% {
    transform: translate(0, 0)
  }
  100% {
    transform: translate(24px, 0)
  }
}
