@import '../../../scss/vars.scss';

.customers {
  position: relative;
  margin: 0 auto;

  & > header {
    margin-bottom: $padding-xl;

    h2 {
      font-weight: 800;
      font-size: 1.5 * $text-size;
      line-height: 1em;
      margin: $padding-sm 0;
    }

    section {
      display: flex;
      align-items: center;
    }
  }
}

.data {
  max-width: $container-width-sm;

  header {
    & > ul {
      display: grid;
      grid-template-columns: 20% 20% 25% auto 10%;
      border-bottom: $border;
      border-top: $border;
      padding: $padding-xs 0;
      color: $color-grey;
      font-size: $text-size - 3px;
    }
  }
}

.list {
  & > li {
    padding: $padding-sm 0;
    display: grid;
    grid-template-columns: 20% 20% 25% auto 10%;
    grid-template-rows: 20px 20px;
    font-size: $text-size - 1px;
    border-bottom: $border;

    &:last-child {
      border: 0;
    }
  }
}

.noResults {
  padding: $padding 0;
  font-size: $text-size - 1px;
}

.noData {
  padding-top: $padding-xs;
  p {
    font-size: $text-size - 1px;
    color: $color-grey;
    font-weight: 100;
    margin: 0;
  }

  button {
    margin-top: $padding-sm;
  }
}

.search {
  width: 50%;

  & > span {
    border-radius: $border-radius;
  }
}

.name {
  font-weight: bold;
}

.address {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 2;
  color: $color-grey;
}

.actions {
  grid-column-start: 5;
  grid-row-start: 1;
  grid-row-end: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    &:last-child {
      display: none;
    }
  }
}

.add {
  margin-left: auto;
  span {
    &:last-child {
      display: none;
    }
  }
}

.drawer {
  & > div:last-child {
    width: 500px !important;
  }
}

@media (max-width: 425px) {
  .customers {
    & > header {
      margin: 0;
      h2 {
        margin-bottom: $padding-sm;
      }
    }
  }

  .add {
    margin-left: $padding-sm;
    span {
      &:last-child {
        display: block;
        margin: 0 !important;
      }
      &:first-child {
        display: none;
      }
    }
  }

  .data {
    padding: $padding 0;

    header {
     display: none;
    }
  }

  .list {
    & > li {
      grid-template-columns: 100%;
      grid-template-rows: 20px 20px 20px 20px 40px;
    }
  }

  .search {
    width: 100%;
  }

  .phone {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  .email {
    grid-column-start: 1;
    grid-row-start: 3;
  }

  .address {
    grid-column-start: 1;
    grid-row-start: 4;
  }

  .last {
    display: none;
  }

  .actions {
    grid-column-start: 1;
    grid-row-start: 5;
    grid-row-end: 5;
    justify-content: flex-start;

    button {
      &:first-child {
        display: none;
      }
      &:last-child {
        margin-top: $padding-xs;
        display: block;
      }
    }
  }

  .noResults {
    padding: 0;
  }

  .drawer {
    & > div:last-child {
      width: 95% !important;
    }
  }
}
