@import "../../../scss/vars.scss";

.autocomplete-adress {
  width: 100%;

  .ant-select-selector {
    line-height: 38px;
    height: 38px !important;
    border-radius: $border-radius !important;

    .ant-select-selection-search {
      font-family: $family-serif;
      font-size: $text-size + 1;
    }
  
    .ant-select-selection-placeholder {
      line-height: 38px!important;
      font-family: $family-serif;
      font-size: $text-size + 1;
    }
  }
}

.autocomplete-option {
  svg {
    width: 15px;
  }
}
