@import '../../../scss/vars.scss';

div.settings-general {
  div.ant-card-body {
    padding: $padding-sm;
  }

  header {
    display: flex;
    align-items: center;
    margin-bottom: $padding-sm;

    .section-name {
      font-weight: bold;
    }

    .edit-btn {
      line-height: normal;

      span {
        text-decoration: underline;
        color: $color-link;

        &:hover {
          opacity: .75;
        }
      }
    }

    .trigger {
      margin-left: auto;
    }
  }

  .business-info {
    section, footer {
      margin-top: $padding-lg;

      &.business-info-section {
        margin-top: $padding-sm;
      }
    }
    section {
      display: flex;
      align-items: flex-start;

      span {
        display: block;
      }
      div.switch {
        margin-left: auto;

        button {
          margin-top: 0;
        }
      }
    }
    footer {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .contact-links {
    div.contact-link {
      margin-top: 10px;
    }
  }
}
