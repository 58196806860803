@import "../../scss/vars.scss";

.share-biz {
  header {
    flex-direction: column;
    margin-bottom: $padding;

    h2 {
      line-height: 1em;
      font-size: 22px;
      font-weight: bold;
      margin: 0;
    }
    h4 {
      margin: $padding-sm 0 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5em;
    }
  }

  canvas {
    width: 150px !important;
    height: 150px !important;
  }

  footer {
    display: flex;
    align-items: center;
    margin-top: $padding;
    justify-content: flex-end;

    .left {
      margin-left: auto;
    }

    button:last-child {
      margin-left: $padding-sm;
    }
  }

  .data {
    display: flex;
    justify-content: space-between;

    .qr {
      margin-right: $padding;
    }

    .copy-link-button {
      border: none;
      font-weight: 800;
      font-size: large;
    }

    .url {
      font-style: italic;
      color: $color-primary;
    }

    .lk {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      align-items: flex-start;
      font-size: large;

      label {
        line-height: 1em;
        font-size: 0.9em;
      }
    }
  }
}

.url-modal {
  min-width: 600px;
  h2 {
    font-weight: 700;
  }

  p {
    font-size: $text-size;
    line-height: 1.5em;
    margin: 0;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    border-radius: $radius-large;
  }

  .ant-modal-footer {
    border: 0;
    text-align: center;
  }

  .ant-modal-body {
    padding: $padding;
  }

  .ant-spin {
    background: #fff;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .ant-alert {
    border-radius: $border-radius;
    font-size: 13px;
    line-height: 1.5em;
  }

  .err {
    margin: 0 0 $padding;
    color: $err-color;
  }
}

@media (max-width: 768px) {
  .share-biz {
    .data {
      justify-content: center;
      flex-direction: column;

      .qr {
        display: none;
      }
      .lk {
        display: inline-block;
        width: 100%;

        .web-url {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .share-biz {
    .data {
      justify-content: center;
      flex-direction: column;
    }

    footer {
      flex-direction: column;

      button {
        width: 100%;
        margin: 0 0 $padding;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
