@import '../../scss/vars.scss';

.light-not-found {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 7vh;
    line-height: 10vh;
    font-weight: 900;
    letter-spacing: -.5px;
    margin: 0;
  }

  h2 {
    font-size: 5vh;
    line-height: 7vh;
    margin-top: 2vh;
    font-weight: 100;
    text-align: center;
  }

  .emoji {
    font-size: 10vh;
    line-height: 1.25em;
  }

  img {
    width: 70px;
    height: 70px;
    margin-top: 25vh;
  }
}
