@import '../../../scss/vars.scss';

.import-item-modal.ant-modal:not(.details-item-modal):not(.business-hours-modal) {
  top: 50px;

  .ant-modal-content {
    width: 900px;
    padding: 30px 20px 20px;
  }

  .ant-modal-footer {
    display: none !important;
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: $padding-sm;

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;

      .anticon {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
          color: $color-text;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .ant-input-affix-wrapper {
      width: 300px;
      border-radius: 20px;
      height: 40px;

      svg {
        color: $color-text;
      }

      input::placeholder {
        font-size: 15px;
      }
    }

    h6 {
      font-size: $text-size-xs;
      color: $color-grey;
    }

    h2 {
      font-weight: 900;
      line-height: 1em;
      margin: 0;
      font-size: $text-size-lg;
    }

    .back {
      margin-bottom: $padding-sm;

      button {
        font-weight: 900;
      }
    }
  }

  ul.designs {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    & > li {
      list-style: none;
      flex: 0 0 25%;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      user-select: none;

      &:hover,
      &.act {
        background: #F4F4F4;
        border-radius: $border-radius;
      }

      img {
        width: 100%;
      }

      h5 {
        font-size: $text-size - 1px;
        margin: $padding-xs 0 0;
      }
    }
  }

  .scroller {
    height: 55vh;
    overflow: auto;
  }

  ul.objects {
    & > li {
      display: flex;
      padding: 0 130px;
    }
  }

  ul.items {
    flex: 1;
    margin-left: $padding-sm;
    li {
      display: flex;
      padding: $padding-xs;
      border: $border;
      border-radius: $border-radius;
      margin-bottom: $padding-xxs;
      align-items: center;
      user-select: none;
      cursor: pointer;

      &.act {
        background: #F6F6F6;
      }

      .ant-checkbox-wrapper {
        margin-right: $padding-xs;
        position: relative;
        top: -2px;
      }

      label {
        line-height: 1em;

        &.price {
          margin-left: auto;
        }
      }
    }
  }

  footer {
    margin-top: $padding-sm;
    display: flex;
    justify-content: center;

    button {
      &:first-child {
        margin-right: $padding-xxs;
      }
      &:last-child {
        margin-left: $padding-xxs;
      }
    }
  }

  .ant-spin {
    margin-top: $padding-sm;
    display: flex;
    justify-content: center;

    svg {
      font-size: 20px;
    }
  }

  &.copy-menu-modal {
    header {
      .browser-nav {  
        display: flex;
        gap: 5px;
        align-items: center;  
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 15px;

        >.help-btn {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 4px;
          margin-right: 5px;
          cursor: pointer;

          .anticon {
            width: 100%;
            height: 100%;

            svg {
              width: 100%;
              height: 100%;
              color: $color-text;
            }
          }

          &:hover {
            background-color: $color-btn-light-grey;
          }
        }

        >span {
          font-size: 13px;
        }
      }
    }

    .scroller {
      height: 48vh;
    }
  }

  &.copy-menu-help-modal {
    .ant-modal-content {
      width: 100%;
      height: 560px;
      padding: 20px 138px 40px;
      text-align: center;

      .video-container {
        width: 100%;
        height: 301.25px;
        margin-bottom: 16px;
        background-color: $color-btn-light-grey;

        video {
          width: 100%;
        }
      }

      h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
        margin: 0;
      }

      p {
        height: 50px;
        margin: 0 0 16px 0;
      }

      .slide-nav {
        width: 146px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .anticon {
          height: 20px;
          width: 20px;
          cursor: pointer;

          svg {
            height: 100%;
            width: 100%;
            color: $color-text;
          }

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    @media screen and (max-width: $mobile-width) {
      .ant-modal-content {
        padding: 20px 20px 30px;

        .video-container {
          height: unset;
        }
      }
    }
  }
}
