@import '../../../scss/vars.scss';

.time-wrapper {
  display: flex;
  flex-direction: column;
  flex: 8 !important;

  .time-actions {
    display: flex;

    span {
      justify-content: flex-start;
      font-weight: 800;
    }

    button {
      margin-left: auto;
      padding-right: 0;
      color: $color-text;

      span {
        font-weight: initial;
        text-decoration: underline;
      }
    }
  }

  .timepicker {
    cursor: pointer;

    .ant-picker {
      width: 100%;
    }

    input {
      cursor: pointer;
    }
  }
}

.banner.schedule .separator {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: 425px) {
  .picker-dropdown {
    .ant-picker-datetime-panel {
      display: block;
    }
  }
}
