@import '../../../scss/vars.scss';

.modifiers {
  display: flex;
  flex-direction: column;

  >button {
    margin-top: $padding-xs;
    margin-right: auto;
  }
}

.addModifier {
  header {
    text-align: center;
  }

  section {
    button {
      width: 100%;
    }
  }
}

.addButton {
  margin-top: $padding-sm;
}