@import '../../../scss/vars.scss';

.preview {
  width: $dashboard-preview-size;
  max-height: $dashboard-preview-size;
  background-size: cover;
  background-position: center center;
  min-height: $dashboard-preview-size;
  border-radius: $border-radius;
}

.no {
  border: 1px solid $color-btn-border-grey;
  background-color: $color-off-white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-grey;
  font-size: $text-size - 3px;
  font-weight: 100;

  >span {
    width: 28px;
    height: 24px;

    >svg {
      width: 100%;
      height: 100%;
      color: $color-light-grey;
    }
  }
}
