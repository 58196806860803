@import 'src/scss/vars';

.custom-picker-wrapper {
  display: flex;
  flex-direction: column;
  background: $color-off-white;
  border: $border;
  max-width: 215px;

  > div {
    padding: $padding-sm;

    &:last-child {
      padding-top: 0;
    }
  }

  .custom-picker {
    display: flex;

    div {
      flex: 1;

      &:first-child {
        flex: 8;
      }
    }

    .chrome {
      position: relative;
      min-height: 150px;
      min-width: 150px;
      cursor: crosshair;
      margin-right: $padding-xxs;
    }

    .hue {
      position: relative;
      cursor: row-resize;
      width: 20px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;

    > div:last-child {
      margin-top: $padding-xs;
    }

    .input {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-text-grey;

      > * {
        flex: 1;
      }
    }

    .brand {
      display: flex;
      flex-direction: column;
      color: $color-text-grey;

      label {
        margin-bottom: $padding-xxs;
      }
    }
  }
}
