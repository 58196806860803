@import '../../../scss/vars.scss';

.section {
  padding: 20px 30px;
  position: relative;

  h2 {
    font-weight: 800;
    margin: 0;
  }

  &>header {
    display: flex;
    align-items: center;

    section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 25px;
    }

    >button {
      display: flex;
      justify-content: center;
      margin-left: 15px;
      position: relative;
      padding: 4px;
      width: 30px;
      height: 30px;

      >span {
        width: 100%;
        height: 100%;

        svg {
          width: 100%;
          height: 100%;
          color: $color-text;
        }
      }

      &:hover {
        background-color: $color-btn-light-grey;
      }
    }

    input {
      flex: 20;
      font-weight: 800;
      font-size: 18px;
      padding: 0 $padding-sm;
      height: 40px;
    }
  }

  &>section {
    margin-top: $padding-xs;
    display: flex;
    align-items: center;

    input {
      flex: 20;
      min-height: 35px;
    }

    p {
      flex: 1;
      margin: 0;
    }

    textarea {
      margin-top: $padding-xs / 2;

      &::placeholder {
        font-size: 15px;
      }
    }

    footer {
      margin-top: $padding-xs;
      text-align: right;

      button {
        margin-left: $padding-xs;
      }
    }
  }
}

.rt {
  margin-left: auto !important;
}

.lk {
  padding: 0;
  color: $color-grey;
  height: auto;

  svg {
    font-size: $delete-icon-size !important;
  }
}

.add {
  margin-top: $padding;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span svg {
    color: $color-btn-dark-grey;
  }

  [class*="btn-sec-2"] {
    margin-left: 10px;
    span {
      svg {
        color: white !important;
      }
    }
  }
}
