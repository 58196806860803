@import "../../../scss/vars.scss";

.edit-location-warning-modal {
  text-align: center;

  .ant-modal-close {
    display: none;
  }

  .ant-modal-content {
    width: 100% !important;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 30px 60px !important;

    h3 {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-bottom: 3px;
    }

    p {
      a {
        text-decoration: underline;
        color: $color-link;

        &:hover {
          opacity: .75;
        }
      }

      font-size: 15px;
      line-height: 21px;
      margin-top: 0 !important;

      &.add-loc-link {
        margin: 30px 0 !important;
      }
    }

    .ant-modal-footer {
      margin-top: 0 !important;
    }

  }
}
