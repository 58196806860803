@import '../../scss/vars.scss';

.app.ntf {
  .dashboard {
    & > main { top: $main-notification-height; }
  }
}

.dashboard {
  background: #fff;
  display: flex;
  z-index: 1;

  & > main {
    background: $dashboard-bg;
    border-radius: $radius-large 0 0 $radius-large;
    min-height: 0;
    position: fixed;
    top: $dashboard-header-height;
    left: 0;
    bottom: 0;
    right: 0;

    & > .scroller {
      position: absolute;
      overflow: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  header.sticky {
    display: flex;
    padding: $padding-sm $padding;
    position: sticky;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    align-items: center;
    user-select: none;
    background: #FFF;
    border-bottom: 1px solid #E8E8E8;
    height: $dashboard-header-height + 1px;

    h2 {
      margin: 0;
      font-size: 1.25 * $text-size;
    }

    .actions {
      margin-left: auto;

      .ant-btn {
        margin-left: $padding-xs;
      }
    }
  }

  .ant-card {
    border: $border;
    margin-bottom: $padding;
    border-radius: $radius-large;
  }
}

.dash-drop-menu {
  padding: $padding-xs 0;

  .ant-divider-horizontal {
    margin: $padding-xs 0;
  }
  .ant-list-item {
    a {
      transition: all 0.25s ease;
      display: block;
      padding: $padding-xs $padding-sm;
      font-size: $text-size;

      &:hover {
        background: #FAFAFA;
      }
    }
    .ant-btn {
      display: block;
      font-weight: 400;
      width: 100%;
      text-align: left;
      font-size: $text-size;
      height: auto;

      &:hover {
        background: #FAFAFA;
      }
    }
  }
}

.share-modal {
  width: 60% !important;

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-body {
    padding: $padding;
  }

  .ant-modal-content {
    border-radius: $radius-large;
  }
}

.first-time-user-modal {
  width: 80% !important;
  max-width: -webkit-fill-available;
  margin: auto !important;
  
  .ant-modal-footer {
    display: none !important;
  }

  .ant-modal-body {
    padding: $padding;
  }

  .ant-modal-content {
    width: unset !important;
  }
}

@media (max-width: 768px) {
  .dashboard {
    & > main {
      left: 0;
      background: #FFF;
      top: $dashboard-header-height-mb;
    }

    h2.fancy {
      font-size: 20px;
    }

    header.sticky {
      background: #FFF;
      border-bottom: 1px solid #EEE;
    }
  }

  .share-modal {
    width: 90% !important;
  }
}

@media (max-width: 425px) {
  .dashboard {
    header.sticky {
      padding: $padding-sm 0;
    }
  }
}
