@import '../../../scss/vars.scss';

div.contact-link {
  display: flex;
  align-items: center;

  .icon {
    width: 20px;
  }

  .text {
    margin-left: $padding-xs;
  }

  .trigger {
    margin-left: auto;
  }

  .ant-input-wrapper {
    max-width: 85%;
  }

  .ant-input-group-addon {
    width: 45px;
  }
  input.phone-input.input-with-addon {
    margin-left: 45px;
  }
  .phone-input-container {
    width: 100%;
    .ant-input-group-wrapper {
      width: 85%;
    }
    .input-with-addon {
      width: calc(85% - 45px);
    }
  }
}
