@import '../../../scss/vars.scss';

.container-interval {
  display: inline-flex;

  &:not(:first-child) {
    padding-top: $padding-sm;
  }

  .separator {
    padding: 0 $padding-xs;
  }

  .buttons {
    padding-top: 4px;
    padding-left: $padding-xs;
  }

  .ant-input {
    min-width: 40px;
  }
}
