@import '../../../scss/vars.scss';

.list {
  width: $dashboard-secondary-sidebar-width;
  background: #FFF;
  align-self: flex-start;
  position: fixed;
  top: $header-height;
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding-top: 70px;
  padding-right: 24px;
  padding-bottom: 75px;

  li {
    margin-top: 5px;

    button {
      &:focus {
        color: $color-text;
      }
    }
  }

  button {
    font-weight: normal;
    font-size: $text-size;
    padding: 7px 15px;
    color: $color-text;
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    letter-spacing: 0;

    span {
      line-height: 1em;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 90%;
    }

    &:hover {
      color: $color-text;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
    border-right: 1px solid white;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
    border-right: 1px solid white;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.act {
  >button {
    font-weight: bold;
    margin: 0;
    padding: 7px 15px 7px 25px;
    background: $color-btn-light-grey;

    &:focus {
      background: $color-btn-light-grey;
    }
  }
}

.addMenuBtn {  
  display: flex;
  gap: 8px;
  position: fixed;
  width: $dashboard-secondary-sidebar-width;
  top: $header-height;
  padding-top: 24px;
  padding-right: 24px;
  z-index: 2;
  background: white;
  padding-bottom: 14px;

  button {
    font-weight: bold;
    justify-content: center;
    height: 32px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .list {
    display: none;
  }
}
