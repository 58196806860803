@import '../../scss/vars.scss';

.light-biz-page {
  font-size: $text-size;

  &.with-order-button {
    footer.main {
      margin-bottom: $padding-xl;
    }
  }

  h1 {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.1em;
    padding: 0 $padding-sm;
    text-align: center;
    margin: $padding-sm 0 $padding-sm;
  }

  p.phone {
    text-align: center;
    margin-bottom: 0;

    a {
      color: $color-text !important;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  div.hours {
    display: flex;
    justify-content: center;
    padding-bottom: $padding-sm;
    cursor: auto;
    font-size: 16px;

    .pointer {
      cursor: pointer!important;
    }

    ul {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding-left: $padding;
      position: relative;

      &:before {
        content: '';
        width: 5px;
        height: 5px;
        background: $color-text;
        border-radius: 50%;
        position: absolute;
        left: $padding-xs;
        top: 9px;
      }
    }

    @supports (-moz-appearance:none) {
      span.details {
        margin-left: -$padding-xs;
        margin-inline-start: -$padding-xxs;
        writing-mode: horizontal-tb;
      }
    }

    @-moz-document url-prefix() {
      span.details {
        margin-left: -$padding-xs;
        margin-inline-start: -$padding-xxs;
        writing-mode: horizontal-tb;
      }
    }

    .hours-expand-icon {
      margin-left: 4px;
      cursor: auto;

      svg {
        margin-top: 6px;
        font-size: $padding-xs;
      }
    }

    .av {
      color: $success-color;
      font-weight: bold;

      &.cl {
        color: $err-color;
      }
    }
  }

  ul {
    list-style-type: none;
  }

  .logo {
    padding: $padding-md $padding-md 0 $padding-md;
    text-align: center;

    img {
      max-height: 150px;
      max-width: 100%;
      margin-bottom: $padding;
    }
  }

  nav.main {
    width: inherit;
    --active-menu-item-border: #0000;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-btn {
        font-weight: 400;
        letter-spacing: -.5px;
        font-size: $text-size + 1px;
        padding: .5rem 1rem;
        height: auto;
      }
    }
  }

  div.menu-notes {
    text-align: center;
    max-width: $container-width-compact;
    margin: 1rem auto;
    padding: 0 30px;
  }

  & > main {
    padding: 0 $padding-md;
    max-width: $container-width-compact;
    margin: 1rem auto;

    .section {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 1rem;
      }

      & > header {
        margin-bottom: 0;

        h2 {
          font-weight: 800;
          font-size: 20px;
          line-height: 1.1;
          padding: 1em 0 .5em 0;
          margin: 0 0 .5rem;
        }

        p {
          font-size: $text-size + 1px;
          margin: -10px 0 0 0;
          line-height: 1.1em;
          white-space: pre-line;
        }
      }
    }
  }
}

.wrapword {
  white-space: -moz-pre-wrap !important;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}
