@import '../../scss/vars.scss';

.enable-ordering-modal {
  width: 50% !important;

  .ant-modal-content {
    border-radius: $radius-large;
    height: 100%;
    min-height: 400px;
    display: flex;
  }

  .ant-modal-body {
    height: 100%;
    overflow: hidden;
    position: relative;
    min-height: 400px;
    border-radius: $radius-large 0 0 $radius-large;
    display: flex;
    align-items: center;
    padding: 0 $padding-lg;
  }

  .ant-modal-footer {
    display: none;
  }

  .bg {
    background: $color-premium;
    position: absolute;
    height: 800px;
    width: 400px;
    border-radius: 50%;
    left: -200px;
    top: -200px;
    z-index: 0;
  }

  .circle {
    width: 125px;
    height: 100px;
    background: #ffb2b9;
    position: absolute;
    border-radius: 50%;
    left: 110px;
    top: 50px;
  }

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    h3 {
      font-weight: 700;
      font-size: $text-size-lg;
      line-height: 1.25em;
      margin: 0;
    }

    p {
      font-size: $text-size;
      padding: $padding-sm 0;
      margin: 0;
    }

    button.ant-btn {
      background: $color-premium;
      border-color: $color-premium;
      color: #FFF;
    }

    img {
      width: 180px;
      height: 180px;
      border-radius: $border-radius;
      margin-right: $padding-xl;
    }
  }
}
