.drawer-menu nav>ul>li a,
.drawer-menu nav>ul>li button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
}

;

.nav-separator {
    margin: 10px 0;
    border-top: 1px solid #ddd;
}

.mobile-nav-option {
    display: none;
}

.menu-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 24px;

    .ant-btn {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .mobile-nav-option {
        display: block;
    }

    .menu-buttons {
        display: flex !important;
    }

    .drawer-menu nav .submenu {
        &>ul.vis {
            padding-left: 29px;
        }

        &>ul>li a {
            display: flex;
            align-items: center;

            .drag-handle {
                position: relative;
                margin: 0px;
                margin-right: 10px;
            }
        }
    }
}