@import '../../../scss/vars.scss';

.item {
  padding: 15px 0;
  border-bottom: $border;

  label {
    font-size: $text-size - 1px;
    font-weight: bold;
    margin-top: $padding-sm;
    display: block;
  }

  main {
    display: grid;
    grid-template-columns: auto $dashboard-preview-size;
    font-size: $text-size + 1px;

    & > div {
      height: $dashboard-preview-size;
    }

    aside {
      padding-right: $padding-sm;
      textarea {
        margin-top: $padding-sm;
        font-size: $text-size + 1px!important;
      }
    }
  }

  footer {
    text-align: center;
    margin-top: $padding-sm;
    button:first-child {
      margin-right: $padding-sm;
    }
  }
}

@media (max-width: 425px) {
  .item {
    main {
      display: contents;
      grid-template-columns: auto;
      grid-template-rows: $dashboard-preview-size auto;
      
      & > div {
        margin-top: $padding;
      }
      
      aside {
        padding: 0;
      }

      textarea section {
        margin-top: $padding;
      }
    }
  }
}
