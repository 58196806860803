@import '../../../scss/vars.scss';

.ratings {
  & > header {
    margin: $padding-sm 0 $padding-xl;
    display: flex;
    align-items: center;

    h2 {
      font-weight: 800;
      margin: 0;
      font-size: 1.5 * $text-size;
      line-height: 1.25em;
    }

    & > div {
      flex: 1;
    }
  }
}

.check {
  label {
    font-size: $text-size - 1px;
    margin-left: $padding-xs;
  }
}

.stars {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  h3 {
    margin: 0;
    font-size: $text-size - 2px;
  }
}

.list {
  & > header {
    ul {
      display: grid;
      padding: $padding-xs 0;
      grid-template-columns: 20% 35% 15% 15% auto;
      font-size: $text-size - 2px;
      border-top: $border;
      border-bottom: $border;
      color: $color-grey;
    }
  }

  & > ul {
    li {
      display: grid;
      grid-template-columns: 20% 35% 15% 15% auto;
      padding: $padding-sm 0;
      border-bottom: $border;
      font-size: $text-size - 1px;

      &:last-child {
        border: 0;
      }
    }
  }

  & > p {
    margin-top: $padding-sm;
    color: $color-grey;
    font-weight: 100;
  }
}

.name {
  font-weight: bold;

  & > span {
    height: 20px;
    line-height: 18px;
  }
}

.actions {
  text-align: right;
  button {
    margin-left: $padding-xs;
  }
}

@media (max-width: 425px) {
  .ratings {
    & > header {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }
  .list {
    & > header {
      display: none;
    }

    & > ul {
      li {
        grid-template-columns: 100%;
        grid-template-rows: 25px 25px 25px 25px 25px;
        align-items: center;
      }
    }
  }

  .stars {
    margin-top: $padding;
    align-items: flex-start;
  }

  .actions {
    text-align: left;

    button {
      margin: 0 $padding-xs 0 0;
    }
  }
}
