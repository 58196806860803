@import '../../scss/vars.scss';

.inactivity-modal {
  main, footer {
    margin-top: $padding;
  }
  footer button:not(:first-child) {
    margin-left: $padding-sm;
  }
}
