@import '../../scss/vars.scss';

.menu-preview-wrapper {
  position: fixed;
  right: 15px;
  top: $header-height;
  bottom: 0;
  width: 30%;
  min-width: 350px;
  border-left: $border;
  padding: $padding;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #FFF;

  h6 {
    color: $color-link;
    font-size: $text-size;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 30px;

    &.preview-label {
      color: $color-text;
      font-weight: bold;
    }
  }

  .actions,
  .views {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 6px;

    h6 {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-bottom: 0;

      a {
        color: $color-link;
        text-decoration: underline;
        white-space: nowrap;
  
        &:hover {
          opacity: .75;
        }
      }

      svg {
        color: $color-text;
      }
    }

    .copy-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      cursor: pointer;

      .anticon {
        height: 20px;
        width: 18px;
  
        svg {
          width: 100%;
          height: 100%;
          color: $color-text;
        }
      }

      &:hover {
        background-color: $color-btn-light-grey;
      }
    }
  }

  .views {
    justify-content: flex-start;
    margin-bottom: 30px;

    h6 {
      color: $color-text;
      cursor: default;
    }
  }
}

@media (max-width: 991px) {
  .menu-preview-wrapper {
    display: none;
  }
}
