@import '../../../scss/vars.scss';

.ready-to-active {
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    text-align: center;
    img {
      width: 175px;
    }
    h2 {
      font-weight: 700;
      margin: 0 0 $padding;
    }
    p {
     margin: $padding 0 0;
      font-size: 17px;

      strong {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }
  .ant-modal-footer {
    border: 0;
    text-align: center;
  }
}
