@import '../../scss/vars.scss';

.order-details-drawer {
  .ant-drawer-content-wrapper {
    width: 500px !important;
  }
}

@media (max-width: 425px) {
  .order-details-drawer {
    .ant-drawer-content-wrapper {
      width: 95% !important;
    }
  }

}
