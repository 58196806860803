@import '../../../scss/vars.scss';

.view {
  border-bottom: $border;
  padding: $padding-sm 0;
  cursor: pointer;

  .itemActions {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: flex-end;

    button:first-child {
      width: 28px;
    }

    button:not(:first-child) {
      padding: 2px;
      margin: 0 10px 0 13px;
      height: 24px;
      border-radius: 4px;

      &:hover {
        background-color: $color-btn-light-grey;
      }
    }

    span {
      width: 18px;
      height: 18px;

      >svg {
        color: $color-text;
        width: 100%;
        height: 100%;
       }
    }

    >span {
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 4px;

      &:hover {
        background-color: $color-btn-light-grey;
      }
    }
  }

  section {
    margin-left: $padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > div {
      &:first-child {
        max-width: calc(100% - 95px);
      }
      & > span {
        white-space: pre;
        font-size: $text-size;
      }
    }
  }
  
  .content {
    overflow: hidden;

    &.lineClamp {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &.oneLineClamp {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  h3 {
    font-weight: bold;
    font-size: $text-size;
    margin: 0 0 $padding-xs;
    line-height: 1em;
    letter-spacing: -.5px;
    display: flex;
  }

  .ulEnabled {
    display: flex;
    font-size: $text-size;
    
    li {
      margin-right: $padding-sm;
      display: flex;
      flex-direction: column;
    }
  }

  .ulNotEnabled {
    display: flex;
    flex-direction: row;
    font-size: $text-size;
  }

  p {
    font-size: $text-size;
    margin: 0;
    line-height: 1.25em;

    display: flex;

    span {
      display: flex;
      font-style: italic;
      color: $color-secondary;
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      font-size: $delete-icon-size;
    }
  }
}

@media (max-width: 991px) {
  .view {
    grid-template-columns: auto $dashboard-preview-size 30px;
    section {
      margin-left: 0;
    }
    .buttonsContainer {
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 30px;
    }
  }
}
