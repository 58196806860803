@import '../scss/vars.scss';

.start-form {
  h1 {
    font-size: 1.5 * $text-size;
    font-weight: bold;
    margin-bottom: $padding;
    text-align: center;
  }

  .ant-form-item {
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
  }

  .ant-btn-primary {
    width: 100%;
  }

  input {
    height: $input-xl-height;
    padding: $input-lg-padding;

    &[type='password'] {
      height: 35px;

      .ant-input-suffix {
        margin-top: 6px;
      }
    }
  }

  .ant-progress-text {
    display: none;
  }
}

@media (max-width: 425px) {
  .start-form .flx {
    .ant-form-item-control-input-content {
      flex-direction: column;

      .lft {
        margin: $padding-sm auto 0;
      }
    }
  }
}
