@import '../scss/vars.scss';

.auth {
  display: flex;

  .data {
    flex: 1.5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 1.5 * $padding-xl;

    form, .form {
      width: 350px;
    }
  }

  .side {
    flex: 1;
    height: 100vh;
    background: $color-bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    padding: 0 $padding-xl 0 $padding;

    .logo {
      position: absolute;
      left: $padding;
      top: $padding;

      img {
        height: 18px;
      }
    }

    div {
      width: 350px;
    }

    h2 {
      font-size: 2.5 * $text-size;
      line-height: 1.25em;
      font-weight: 700;
      color: $color-text;
    }

    .ant-btn-dashed {
      line-height: 58px;
      height: 60px;
      background: transparent;
      font-weight: 600;
      border: 1px dashed $color-text;
      box-shadow: none;
      padding: 0 $padding-lg;
      color: $color-text;
    }
  }
}

@media (max-width: 1024px) {
  .auth {
    .data {
      padding-left: $padding-xl;

      form, .form {
        width: 300px;
      }
    }
    .side {
      padding-right: $padding;
      div {
        width: 300px;
      }
    }
  }
}

@media (max-width: 768px) {
  .auth {
    .data {
      padding: 0 $padding;

      form, .form {
        width: 100%;
      }
    }
    .side {
      padding: 0 $padding;

      div {
        width: 100%;
      }

      h2 {
        font-size: 1.5 * $text-size;
      }

      button {
        height: 50px;
        padding: 0 $padding;
      }
    }
  }
}

@media (max-width: 600px) {
  .auth {
    .side {
      display: none;
    }
    .data {
      min-height: 99vh;
      form, .form {
        align-self: center;
        width: 300px;
      }
    }
  }
}
