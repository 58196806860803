@import "./scss/vars";
@import "./scss/ant";
@import "./scss/fonts";
@import "./scss/ant-tabs";
@import './scss/biz-page.scss';
@import "../node_modules/react-input-range/lib/css/index.css";

body {
  background: #FFF;
  margin: 0;
  font-size: 16px;
  font-family: $family-sans-serif;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  user-select: none;
  color: $color-link;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $family-sans-serif;
  color: $color-text;
  &.ant-typography {
    color: $color-text;
  }
}

.flx {
  display: flex;
  .lft {
    margin-left: auto;
  }
}

h4 {
  margin-top: $padding;
}

p.det {
  font-size: .9em;
  color: #888;
}

h2.fancy {
  font-size: 30px;
  color: $color-text;
  letter-spacing: -.5px;
  strong {
    font-weight: 800;
  }
}

.ant-btn.cta {
  background-color: $color-secondary;
  border-color: $color-secondary;
  text-shadow: none;
  &:hover {
    opacity: .9;
  }
}

.btn-xl {
  height: 60px;
  line-height: 58px !important;
  padding: 0 45px;
  font-size: 1.1em;
}

.hidden {
  display: none !important;
}

.condensed {
  font-family: $family-sans-serif;
}

.split {
  display: flex;
  flex-wrap: wrap;

  .half {
    flex: 1;

    &:first-child {
      margin-right: $padding-md;
    }
    &:last-child {
      margin-left: 0;
    }
  }
}

.ant-tag.act-men {
  align-self: center;
  border-radius: $border-radius;
  border: 0;
  background: $color-secondary;
  color: #FFF;
  font-family: $family-sans-serif;
  font-size: 10px;
  letter-spacing: 0;
  font-weight: 500;
  margin-right: $padding-sm;
  text-align: center;
}

a.lk {
  color: $color-link;
}

label.req {
  &::after {
    content: '*';
    margin-left: 3px;
    font-size: 15px;
  }
}

.anticon {
  svg {
    color: $color-grey;
    font-size: 15px;
  }
}

ul.od-ev {
  margin-top: $padding;
  border: 1px solid #E8E8E8;

  li {
    display: flex;
    font-size: $text-size;
    padding: $padding-sm $padding;

    &.odd {
      background: #f7f8fc;
    }

    & > span {
      flex: 1;
    }

    .act {
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: $padding-xs;
      }
    }
  }
}

.ant-btn.cta-btn {
  background: rgb(238,47,49);
  text-shadow: none;
  background: linear-gradient(135deg, rgba(238,47,49,1) 0%, rgba(242,118,137,1) 100%);
  border: 0;
  color: #FFF;
  font-weight: 800;
  box-shadow: none;

  &:hover {
    background: rgb(238,47,49);
  }
}

.ant-btn.scc-btn {
  background: $success-color;
  text-shadow: none;
  border: 0;
  color: #FFF !important;
  font-weight: 800;
  box-shadow: none;

  &:hover {
    background: $success-color;
  }
}

.ext-lk {
  width: 12px;
  height: 12px;
  fill: $color-link;
}

.undo-remove-notification {
  .ant-message-notice-content {
    font-size: $text-size;
    margin: 0;

    button {
      margin: 0 0 0 auto;
      margin-left: $padding
    }
  }
}

.ant-message {
  .ant-message-success {
    background: #F6FFED;
    border: 1px solid #B7EB8F;
    margin: -10px -16px;
    padding: 8px 16px;
    border-radius: 2px;

    .anticon svg {
      color: #52C41A;
    }
  }
}