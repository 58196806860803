@import '../../../scss/vars.scss';

.res-tax {
  hr {
    border: 0;
    border-bottom: 1px solid #E8E8E8;
    margin: $padding 0 0;
    padding: 0;
  }
}

.tax-modal {
  .ant-input-group.ant-input-group-compact {
    display: flex;

    .ant-input {
      flex: 4;
    }

    .ant-select {
      flex: 1;
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

@media (max-width: 768px) {
  .res-tax {
    ul.od-ev {
      li {
        display: flex;
        flex-direction: column;
        padding: $padding-sm;

        .act {
          justify-content: flex-start;

          button:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}
