@import "vars";

.ant-layout {
  background: #FFF;
  min-height: 50vh;
}

.ant-btn {
  font-family: $family-sans-serif;
  border-radius: $border-radius;
  font-size: $text-size - 1px;
  font-weight: 500;
  letter-spacing: -.25px;
  transition: none;
  box-shadow: none;
}

.ant-btn-lg {
  padding: 0 $padding;
  font-size: $text-size;
  line-height: 40px;
}

.ant-btn-sm {
  text-transform: lowercase;
  font-size: $text-size - 2px;
}

.ant-tag {
  user-select: none;
}

.ant-dropdown-menu {
  border-radius: $border-radius;
  min-width: $dropdown-min-width;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.07), 0 6px 16px 0 rgba(0, 0, 0, 0.04), 0 9px 28px 8px rgba(0, 0, 0, 0.04);
}

.ant-layout-header {
  padding: 15px 0;
  height: $header-height;
  line-height: $header-height;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  padding: 10px 15px;
}

.ant-form-item {
  font-size: $text-size;
  flex-direction: column;
  align-items: flex-start;

  .ant-form-item-control {
    width: 100%;
    text-align: left;
  }

  label {
    color: $color-text;
    line-height: $text-size;
    height: $text-size;
  }

  &:last-child {
    margin: 0;
  }
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-item > button,
.ant-dropdown-menu-item{
  font-size: $text-size;
  font-weight: normal;
  color: $color-text;
}

.ant-dropdown-menu-item > button {
  padding: 0;
}

.ant-tabs{
  font-size: $text-size;
}

.ant-form {
  label {
    font-size: $text-size - 1px;
    font-weight: 500;
  }
  .stacked {
    .ant-row {
      &.ant-form-item {
        flex-direction: column;

        .ant-form-item-label {
          text-align: left;

          & > label {
            height: 26px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.ant-input-password {
  border-radius: $border-radius;
}

.ant-tooltip-inner {
  font-size: 10px;
  min-height: 20px;
  padding: 3px 5px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $color-bg;
}

.ant-radio-button-wrapper {
  user-select: none;
  &:first-child {
    border-radius: $border-radius 0 0 $border-radius;
  }
  &:last-child {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

.ant-input,
.ant-select-selector {
  border-radius: $border-radius !important;
}

.ant-input-group {
  .ant-input {
    border-radius: 0 $border-radius $border-radius 0 !important;
  }

  .ant-input-group-addon {
    border-radius: $border-radius 0 0 $border-radius !important;
    width: 44px;
    padding: 0;

    img {
      width: 22px;
      height: 22px;
    }

    svg {
      margin-top: 5px;
      width: 22px;
      height: 22px;
    }

    .anticon svg {
      color: $color-text;
    }
  }
}

.ant-card-body {
  header {
    display: flex;
    margin-bottom: $padding;

    &.c {
      flex-direction: column;
      margin-bottom: $padding-sm;

      h5 {
        font-size:  $text-size;
        line-height: 1.25em;
        margin: $padding-xs 0 0;
        font-weight: 100;

        a {
          color: $color-link;
          display: inline-block;
        }
      }
    }

    h3 {
      margin: 0;
      font-weight: 800;
      line-height: 1em;
    }
  }
}

.ant-switch {
  background-color: $color-75-grey;

  &.ant-switch-checked {
    background-color: $success-color;
  }
}

.ant-modal-close-x {
  width: 38px;
  height: 38px;
  position: absolute;
  top: -12px;
  right: -12px;
  background: #FFF;
  line-height: 40px;
  border-radius: 50%;
  box-shadow: 0 8px 24px 0 rgba(40,50,66,.24);
}

.drift-frame-controller {
  bottom: 0;
  right: 0;
}

.no-footer-modal {
  .ant-modal-footer {
    display: none !important;
  }
}

.ant-table-wrapper {
  &.compact {
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
      padding: $padding-xs;
    }
  }
}

.ant-modal:not(.details-item-modal):not(.business-hours-modal) {
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    color: #333;
    width: 520px;
    min-height: 180px;
    border-radius: 10px;
  
    .ant-modal-close-x {
      display: none;
    }
  
    .ant-modal-body {
      text-align: center;
      padding: 0;
  
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
      }
  
      p {
        margin: 0;
        font-size: 15px;
      }
    }
  
    .ant-modal-footer {
      border: none;
      display: flex;
      justify-content: center;
      margin-top: 30px;
      padding: 0;
  
      .ant-btn {
        min-width: $min-btn-width;
        height: 40px;
        border-radius: 6px;
        font-weight: bold;
        
        background-color: white;
        border: 1px solid $color-btn-border-grey;
        color: $color-grey;

        &:hover {
          background-color: white;
          border: 1px solid $color-btn-cancel-hover;
          color: $color-btn-cancel-hover;
        }
  
        &.ant-btn-primary {
          background-color: $color-primary;
          border: 1px solid $color-primary;
          color: white;

          &:hover {
            background-color: $color-primary-hover;
            border: 1px solid $color-primary-hover;
            color: white;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .ant-modal-content {
      width: 375px;
      max-width: 100%;
      min-height: 209px;
      margin: auto;
    }
  }
}

.ant-btn {
  border-radius: 6px;
  padding: 0 15px;
  font-weight: bold;
  text-align: center;

  &.ant-btn-primary {
    background-color: $color-primary;
    border: 2px solid $color-primary;
    color: white;

    &:hover {
      background-color: $color-primary-hover;
      border-color: $color-primary-hover;
      color: white;
    }
  }

  &.btn-sec-1 {
    background-color: $color-btn-light-grey;
    border: 2px solid $color-btn-light-grey;
    color: $color-text;

    &:hover {
      opacity: .75;
      background-color: $color-btn-light-grey;
      border: 2px solid $color-btn-light-grey;
      color: $color-text;
    }
  }

  &.btn-sec-2 {
    background-color: $color-btn-dark-grey;
    border: 2px solid $color-btn-dark-grey;
    color: white;

    &:hover {
      opacity: .75;
      background-color: $color-btn-dark-grey;
      border: 2px solid $color-btn-dark-grey;
      color: white;
    }
  }

  &.btn-sec-3 {
    background-color: white;
    border: 2px solid $color-btn-dark-grey;
    color: $color-btn-dark-grey;

    &:hover {
      opacity: .8;
      background-color: white;
      border: 2px solid $color-btn-dark-grey;
      color: $color-btn-dark-grey;
    }
  }

  &.btn-cancel {
    background-color: white;
    border: 1px solid $color-btn-border-grey;
    color: $color-grey;

    &:hover {
      background-color: white;
      border: 1px solid $color-btn-cancel-hover;
      color: $color-btn-cancel-hover;
    }
  }

  &[disabled] {
    opacity: .5;
    background-color: $color-btn-light-grey;
    border: 2px solid $color-btn-light-grey;
    color: $color-text;

    &:hover {
      opacity: .5;
      background-color: $color-btn-light-grey;
      border: 2px solid $color-btn-light-grey;
      color: $color-text;
    }
  }

  &.btn-lg {
    height: 50px;
    font-size: $text-size-btn-lg;
  }

  &.btn-med {
    height: 40px;
    font-size: $text-size;
  }

  &.btn-sm {
    height: 32px;
    font-size: $text-size-btn-sm;
  }
}

.ant-drawer-close {
  margin-left: calc(66vw - 24px);

  .anticon svg {
    fill: $color-text;
    font-size: 20px;
  }
}

.ant-drawer-body {
  padding: 24px;
} 
