@import '../../../scss/vars.scss';

.add-customer-modal {
  .ant-modal-content {
    border-radius: $radius-large;
  }
  .ant-modal-footer {
    display: none;
  }

  h2 {
    font-weight: 800;
    font-size: $text-size * 1.25;
  }
}
