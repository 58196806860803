@import './fonts.scss';

$color-primary: #E42436;
$color-secondary: #de4552;
$color-link: #156CD1;
$color-premium: #693ef6;

$color-text: #333;
$color-light-grey: #999;
$color-border-light-grey: #aaa;
$color-75-grey: #bfbfbf;
$color-grey: #777;
$color-dark-grey: #575757;
$color-text-grey: #a7a7a7;
$color-bg: #f4f6fb;
$color-input-bg: #f5f5f8;
$color-black: #000000;
$color-powered-by: #575757;
$color-share-grey: #efefef;

$border-color: #dadada;

$color-btn-border-grey: #cfcfcf;
$color-btn-light-grey: #efefef;
$color-btn-dark-grey: #565656;
$color-primary-hover: #FF5C69;
$color-btn-cancel-hover: #E42436;
$color-btn-disabled: #EBEBEB80;
$color-gold: #e6b754;
$color-white: #fff;

$err-color: #ff4d4f;
$success-color: #51af2a;
$yellow-color: #ffe11b;

$color-off-white: #fafafa;

$order-progress-color: #f19c2d;

$closed-color: #f04f57;

$order-bar-height: 80px;

$text-size-xxl: 48px;
$text-size-xl: 28px;
$text-size-lg: 22px;
$text-size-md: 20px;
$text-size: 15px;
$text-size-announcement: 15px;
$text-size-sm: 13px;
$text-size-xs: 11px;
$text-size-header-label: 17px;

$text-size-btn-lg: 17px;
$text-size-btn-sm: 14px;

$order-details-footer-height: 80px;
$header-height: 80px;

$min-btn-width: 80px;

$family-sans-serif: 'proxima-nova';
$family-serif: 'proxima-nova';

$radius-large: 8px;
$radius-xlarge: 10px;
$border-radius: 6px;
$border: 1px solid #e0e0e0;
$light-border: 1px solid #EAEAEA;

$dropdown-min-width: 250px;

$padding: 24px;
$padding-xl: 48px;
$padding-xxl: 56px;
$padding-lg: 36px;
$padding-md: 30px;
$padding-sm: 15px;
$padding-xs: 10px;
$padding-xxs: 5px;

$delete-icon-size: 18px;

$dashboard-header-height: 80px;
$dashboard-header-height-mb: 70px;
$dashboard-aside-background: $color-primary;
$dashboard-aside-width: 180px;
$dashboard-aside-height: 70px;
$dashboard-expanded-aside-width: 340px;
$dashboard-bg: #FFFFFF;
$dashboard-max-width: 70%;
$dashboard-preview-size: 80px;
$dashboard-secondary-sidebar-width: 320px;

$container-width: 1200px;
$container-width-sm: 1060px;
$container-width-compact: 800px;
$container-width-wide: 1600px;

$box-shadow: 0 3px 10px rgba(0, 0, 0, .05);

$grid-column-gap: 20px;

$input-lg-height: 50px;
$input-xl-height: 60px;
$input-lg-padding: 8px 15px;

$item-hover-bg: #f4f8ff;

$main-notification-height: 50px;

$mobile-width: 991px;

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-top-small {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-top-small {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}




@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
