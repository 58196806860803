@import "../../scss/vars.scss";
@import "../../scss/mixins.scss";

.design-site-link {
  display: none;
}

.dashboard-design {
  padding: $padding;
  //max-width: $dashboard-max-width;
  display: flex;
  //width: 70%;
  justify-content: center;

  h3 {
    font-weight: 700;
    line-height: 1em;
    //font-size: $text-size-md;
    margin: 0 0 $padding-md;
  }

  .logos {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0 $padding-sm $padding-sm 0;
      height: 110px;
      width: 155px;
      border: 2px dashed #dadada;

      border-radius: $radius-large;

      &.act,
      &:hover {
        border: 2px solid $color-primary;
      }

      .uploader .ant-upload {
        border: 0;

        svg {
          width: 40px;
          height: 40px;
          fill: #888;
        }

        button.ant-button {
          height: auto;
          display: flex;
        }
      }
    }

    .logo {
      border: 2px solid #dadada;
      background-color: #f9f9f9;
    }

    .logo,
    .remove {
      padding: $padding-xs;
      cursor: pointer;
      transition: all 0.25s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 40px;
        height: 40px;
      }

      img {
        width: 100%;
        height: 100%;
        background-size: cover;
        border-radius: $border-radius;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        object-fit: contain;
      }
    }
  }

  .colors {
    display: flex;
    flex-wrap: wrap;
    li {
      display: flex;
      margin-right: $padding;
      cursor: pointer;
      margin-top: $padding-xs;

      &:last-child {
        margin: 0;
        margin-top: $padding-xs;
      }

      span {
        display: inline-block;
        width: 45px;
        height: 45px;

        &:first-child {
          border-radius: $border-radius 0 0 $border-radius;
        }

        &:last-child {
          border-radius: 0 $border-radius $border-radius 0;
        }
      }
    }

    &.single {
      li {
        span {
          border-radius: $border-radius;
        }
      }
    }
  }

  .biz-name {
    font-size: $text-size;
    margin-top: $padding-sm;

    label {
      margin-right: $padding-sm;
    }
  }

  .sections {
    margin-top: $padding-xl;
    & > ul {
      display: flex;
      flex-wrap: wrap;

      & > li {
        margin-right: $padding-sm;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  section {
    width: 80%;

    div.entry {
      border: 1px solid $border-color;
      border-radius: $radius-large;
      padding: $padding;

      &:not(:first-child) {
        margin-top: $padding-md;
      }
    }
  }
}

@media (max-width: 991px) {
  .design-site-link {
    @include position(sticky);
    background: white;
    border-bottom: $border;
    display: flex;
    justify-content: center;
    padding: $padding;
    top: -1px;
    z-index: 2;

    h6 {
      font-size: $text-size;
      font-weight: 700;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 15px;

      a {
        color: $color-link;
        font-weight: 400;
        text-decoration: underline;
        white-space: nowrap;

        &:hover {
          color: $color-link;
          opacity: .75;
        }
      }
    }
  }
  .dashboard-design {
    max-width: 100%;
    width: 100%;
    flex-direction: column;

    .logos li {
      width: 125px;
    }

    section {
      width: 100%;
    }

    .sections {
      & > ul {
        & > li {
          &:last-child {
            margin-right: 15px !important;
          }
        }
      }
    }
  }
}

