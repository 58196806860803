@import '../../scss/vars.scss';

.no-click-overlay {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}

header.dashboard-header {
  display: flex;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: $dashboard-header-height;
  padding: 0 $padding;
  border-bottom: $border;
  align-items: center;
  justify-content: center;

  .drawer-toggle {
    display: none;
    padding: 0;
    height: 24px;

    svg {
      font-size: 20px;
      fill: $color-text;
    }
  }

  span.version-preview {
   font-weight: 800;
  }

  div.publish.revision {
    button {
      background: white;
      border: 0 white;

      span {
        color: black;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -17px;
    margin-right: $padding;
    height: 60px;
    width: 158px;
    border: 1px solid white;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background: #ededed;
      border: 1px solid #ededed;
    }

    .back-arrow-hitbox {
      height: $dashboard-header-height;
      width: 51px;
      display: flex;
      align-items: center;
      margin-left: -19px;

      img.back-arrow {
        margin: auto;
      }
    }

    img.oo-logo {
      height: 42px;
    }

    label {
      color: $color-primary;
      line-height: 1.2em;
      font-size: 17px;
      font-weight: bold;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  & > nav {
    ul {
      height: 100%;
      display: flex;
      align-items: center;

      li {
        margin-right: $padding-xs;

        a, button.ant-btn-link {
          font-size: 18px;
          color: $color-text;
          border: 0;
          font-weight: 500;
          border-bottom: 2px solid #FFF;
          display: inline-block;
          padding: $padding-xs;
          letter-spacing: 0;
          border-radius: 0;
          line-height: 1em;
          height: auto;
          font-family: $family-sans-serif;
          min-width: $min-btn-width;
          text-align: center;

          label {
            cursor: pointer;

            img, svg {
              @media (min-width: 769px) {
                display: none;
              }
            }
          }

          &.active {
            color: $color-text;
            border-color: $color-primary;
            font-weight: bold;
          }

          &:hover {
            border-color: $color-primary;
          }
        }

        button.ant-btn-link {
          padding-right: $padding;
        }
      }
    }
  }

  .beta {
    color: $color-link;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 8px;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .publish {
    margin-left: auto;

    span.info {
      font-style: italic;
      padding-right: $padding-sm;
      color: $color-light-grey;
      font-size: 12px;
    }

    .ant-btn {
      font-weight: bold;
      background: $color-dark-grey;
      border-color: $color-dark-grey;

      &.share-btn {
        background: $color-share-grey;
        border-color: $color-share-grey;
        margin-right: 6px;
        color: $color-text;

        &:hover {
          color: $color-text;
        }
      }

      &:hover {
        opacity: .8;
      }
    }
  }
}

.drawer-menu {
  nav > ul > li {
    a, button {
      display: block;
      padding: 0;
      line-height: 1.5 * $text-size-xl;
      height: 1.5 * $text-size-xl;
      color: $color-text !important;
      font-size: $text-size;
      border: 0;
      width: 100%;
      text-align: left;

      &:not(.active) {
        font-weight: normal;
      }

      &.active {
        font-weight: bold;
      }

      .nav-icon {
        img {
          width: 22px;
          height: 22px;
          margin-right: 8px;
        }

        svg {
          fill: $color-text;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          transform: translate(0, 3px);
        }
      }
    }
  }

  .submenu {
    .arrow svg {
      margin-left: $padding-xs / 2;
      fill: $color-text;
    }

    & > ul {
      display: none;
      padding-left: $padding;
      &.vis {
        display: block;
        padding-left: 47px;
        margin-top: -12px;
        margin-bottom: 20px;

        li a {
          height: 32px;
        }
      }
    }
  }
}

main main .publish {
  display: none;
}

@media (max-width: 991px) {
  .publish {    
    .share-btn {
      display: none;

      &.ant-btn {
        padding: 0 8px;
      }

      .share-icon {
        display: block !important;
      }
      
      .share-text {
        display: none;
      }
    }

    .info {
      display: none;
    }
  }

  main main .publish {
    display: block;

    .info {
      display: none;
    }

    .ant-btn {
      font-weight: bold;
      background: $color-dark-grey;
      border-color: $color-dark-grey;

      &.share-btn {
        display: block;
        background: $color-share-grey;
        border-color: $color-share-grey;
        margin-right: 6px;
        color: $color-text;

        &:hover {
          color: $color-text;
        }
      }
      
      span {
        font-weight: bold;
      }

      &.publish-changes-btn {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  header.dashboard-header {
    height: $dashboard-header-height-mb;
    justify-content: space-between;

    .logo {
      width: 76px;

      label {
        display: none;
      }
    }

    .lg {
      display: none;
    }

    & > nav {
      display: none;
    }

    .drawer-toggle {
      display: block;
    }

    .publish {
      margin-right: 20px;

      .share-btn {
        display: none;
      }
    }
  }

  main main .publish {
    margin-top: -3px;
  
    .info {
      display: none;
    }
  }
}
