@import '../../../scss/vars.scss';

.rs-set {
  p {
    font-size: 13px;
    margin-bottom: $padding;
  }

  .mg-tp {
    display: block;
    margin-top: $padding;
  }
}
