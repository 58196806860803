@import '../scss/vars.scss';

.selected-overlay {
  position: absolute;
  right: 0;
  top: 0;
  padding: $padding-xs $padding-sm 0 0;
  z-index: 1;

  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    border-radius: $border-radius;
    border: 0;

    &:after {
      border: 3px solid #fff;
      border-top: 0;
      border-left: 0;
      top: 48%;
      left: 28%;
      width: 8px;
      height: 16px;
    }
  }
}
