@import '../../../scss/vars.scss';

.menu {
  width: calc(70% - 326px);
  border-left: $border;
  margin-left: $dashboard-secondary-sidebar-width;

  h1 {
    font-size: 2 * $text-size;
    font-weight: 800;
    margin: 0;
  }

  & > header {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    background-color: $color-off-white;

    div {
      display: flex;
      width: 100%;
      align-items: center;

      button {
        margin-left: $padding-xs;
        position: relative;
      }

      input {
        flex: 21 1;
        font-weight: 800;
        font-size: 18px;
        padding: 9px 15px;
        height: 40px;
      }

      &:last-child {
        input {
          font-weight: initial;
          font-size: $text-size;
        }
      }

      section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      >span {
        height: 30px;
        width: 30px;
        padding: 5px;
        border-radius: 4px;        

        >span {
          width: 100%;
          height: 100%;

          svg {
            width: 100%;
            height: 100%;
            color: $color-text;
          }
        }

        &:hover {
          background-color: $color-btn-light-grey;
        }
      }

      p {
        flex: 5 1;
        margin-left: $padding-sm;
      }
    }
  }

  & > footer {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    background: #FFF;
    padding: $padding-sm;
    margin-top: $padding;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: $border;
    justify-content: space-between;
    z-index: 1;
  }

  & > section {
    display: flex;
    align-items: center;

    label {
      font-size: $text-size - 1px;
      margin-left: $padding-xs;
    }
  }

  .newSection {
    width: 100%;
    height: 40px;

    svg {
      color: $color-btn-dark-grey;
      transform: translateY(1px);
    }
  }

  .saveButton {
    margin-top: $padding-sm;
  }

}

.rt {
  margin-left: 15px !important;

  svg {
    font-size: $delete-icon-size !important;
  }
}

.lk {
  color: $color-grey;
}

.remove {
  color: $err-color;
  border-color: $err-color;
}

.err {
  color: $err-color;
  border-color: $err-color;
  font-size: $text-size - 1px;
}

.err:focus {
  color: black;
}

.publishSection {
  margin-left: 15px !important;
}

@media (max-width: 991px) {
  .menu {
    width: 100%;
    margin-left: 0;

    & > header {
      div {
        input {
          flex: 72 1;
        }
      }
    }
  }
}
