@import '../../../scss/vars.scss';

.alt-text-modal {
  width: 570px;
  height: 570px;
  top: 10%;

  .close-btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 22px;
    height: 25px;
    cursor: pointer;

    .anticon {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
        fill: $color-text;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-body {
      padding: 0 98px 40px 98px;
      color: $color-text;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 530px;
  
      .img-cont {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
  
        >img {
          max-width: 200px;
          max-height: 200px;
        }
      }
  
      h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin: 0 0 3px;
  
        svg {
          width: 26px;
          height: 26px;
        }
  
        .help-btn {
          width: 24px;
          height: 24px;
          margin-left: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
  
          .anticon {
            width: 18px;
            height: 18px;
  
            svg {
              width: 100%;
              height: 100%;
            }
          }
  
          &:hover {
            background-color: $color-btn-light-grey;
          }
        }
      }
  
      p {
        margin: 0 0 15px;
      }
  
      textarea {
        padding: 9px 15px;
        height: 90px;
        resize: none;
        border-radius: 6px;
        border: 1px solid $color-btn-border-grey;
  
        &:focus-visible {
          border: 1px solid $color-primary;
          outline: none;
        }
      }

      footer {
        margin-top: 0;
      }
  
      .btn {
        width: 100%;
        height: 40px;
        margin: 15px 0 0;
  
        &.skip {
          border: none;
          color: $color-text;
          line-height: 21px;
          height: 21px;
          margin-top: 12px;
  
          &:hover {
            opacity: 0.7;
          }
        }
  
        &[disabled] {
          pointer-events: none;
          opacity: 0.4;
          background-color: $color-primary;
          color: white;
          border: 2px solid $color-primary;
        }

        &.delete-icon-btn {
          border: none;
          color: $color-grey;
          height: 20px;
          margin-top: 10px;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    } 
  }

  &.alt-text-help-modal {
    height: 362px;
    top: calc(10% + 104px);

    .ant-modal-content .ant-modal-body {
      height: 362px;
      padding: 40px 20px;
      justify-content: space-between;

      h3 {
        justify-content: flex-start;
      }

      p {
        text-align: left;
        margin: 0;
      }
    }
  }

  .ant-modal-footer {
    display: none !important;
  }

  @media screen and (max-width: $mobile-width) {
    width: 100vw !important;
    margin: 0;

    .ant-modal-content {
      width: 100vw;

      .ant-modal-body {
        padding: 20px;
      }
    }
  }
}