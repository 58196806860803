@import 'src/scss/vars';

.pointer-hue {
  min-height: 5px;
  background: white;
  width: 100%;
  display: flex;
  margin-top: -2.5px;

  svg {
    width: 100%;
    height: 5px;
    border: 1px solid;
  }

  #box {
    fill: white;
    stroke: black;

    width: 100%;
  }
}
