@import '../scss/vars.scss';

.app-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    height: 40px;
  }

  p {
    color: $color-grey;
    margin-top: $padding-sm;
    font-size: 12px;
  }
}
