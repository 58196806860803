@import '../../scss/vars.scss';

.design-color-picker {
  h6 {
    font-size: $text-size;
  }

  .field {
    display: flex;
    border: $border;
    border-radius: $border-radius;
    width: 150px;
    padding: $padding-xs;
    line-height: 1em;
    user-select: none;
    position: relative;

    span {
      width: 38px;
      height: 38px;
      background-color: rgb(0, 0, 0);
      position: absolute;
      top: -1px;
      right: -1px;
      border-radius: 0 $border-radius $border-radius 0;
      border: $border;
    }
  }

  .color-picker {
    label {
      font-family: $family-serif !important;
    }
  }
}
