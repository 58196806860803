@import '../../scss/vars.scss';

.call-btn {
  padding: $padding-sm $padding;
  display: inline-block;
  position: fixed;
  bottom: $padding;
  font-size: 1em;
  font-weight: 800;
  line-height: 1em;
  border-radius: $border-radius;
  width: 200px;
  left: 50%;
  text-align: center;
  margin-left: -100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);

  &.preview {
    position: absolute;
  }

  &:hover {
    box-shadow: none;
  }
}

button.call-btn {
  border: 0;
}