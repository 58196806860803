@import '../../../scss/vars.scss';

.customer {
  padding: $padding-sm 0;
  & > header {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 30px 20px;
    margin-bottom: $padding;

    h2 {
      font-size: $text-size * 1.5;
      font-weight: bold;
      letter-spacing: -.5px;
      margin: 0;
      line-height: 1em;
      align-self: center;
    }

    h3 {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a > span {
        margin-right: $padding-xs / 2;
      }
    }

    h6 {
      margin: 0;
      font-size: $text-size;
      color: $color-grey;
      font-weight: 100;
    }
  }

  section {
    margin-bottom: $padding;

    label {
      font-size: $text-size - 2px;
      font-weight: 100;
    }

    p {
      line-height: 1.25em;
      margin: 0;
    }
  }
}

.orders {
  & > header {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    color: $color-grey;
    border-top: $border;
    border-bottom: $border;
    padding: $padding-xs / 2 0;
    margin-top: $padding-xs
  }

  & > ul {
    li {
      display: grid;
      grid-template-columns: 25% 50% 25%;
      padding: $padding-xs / 2 0;
    }
  }
}

.tr {
  text-align: right;
}

@media (max-width: 425px) {
  .customer {
    & > header {
      grid-template-columns: 100%;
      grid-template-rows: 40px 20px 20px;
      margin: 0 0 $padding-sm;
      align-items: center;

      h3 {
        justify-content: flex-start;
      }
    }
  }
}
