@import './vars.scss';

.biz-page {
  .social {
    display: flex;

    li {
      margin-right: $padding-sm;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .hero {
    background-color: $color-bg;
    background-size: cover;
    background-position: center;
    border-radius: 2 * $radius-large;
    padding: $padding-xl 0;
    display: flex;
    position: relative;
    min-height: 275px;
    margin: 0 auto;

    & > .container {
      flex-direction: row;
      padding: 0 $padding-xl;
      align-items: center;
    }

    h2 {
      font-weight: 800;
      font-size: 2.5 * $text-size;
      line-height: 1em;
      margin-bottom: $padding-lg;
    }

    p {
      font-weight: 100;
      font-size: $text-size + 2px;
      margin-bottom: $padding;
      letter-spacing: -.5px;
    }

    button {
      align-self: flex-start;
      background: none;
      box-shadow: none;
      border-width: 1px;
      color: $color-text;
      border-color: $color-text;
    }

    .pitch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }

    .cover {
      width: 300px;
      height: 300px;
      background-size: cover;
      background-position: center center;
      margin-left: auto;
      border-radius: 50%;
    }
  }

  nav.main {
    margin-top: $padding-xl;
    ul {
      display: flex;
      border-top: $border;
      border-bottom: $border;

      li {
        margin-right: $padding-sm;
        padding-right: $padding-sm;
        border-right: $border;
        display: flex;

        & > a, & > span, & > button {
          font-size: $text-size - 1px;
          padding: 1.5 * $padding-sm $padding;
          height: auto;
          line-height: 1em;
          border: 0;
        }

        &:last-child {
          margin: 0;
          padding: 0;
          border: 0;
        }

        &:empty {
          display: none;
        }
      }
    }
  }
}

.biz-header {
  display: flex;
  align-items: center;
  padding: $padding-lg 0;

  .logo {
    height: 30px;
    width: 30px;
    background-size: cover;
    background-position: center;
  }

  .container {
    flex-direction: row;
    align-items: center;
  }

  nav.top {
    margin-left: auto;
    & > ul {
      display: flex;
      align-items: center;

      & > li {
        margin-right: $padding;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  h1 {
    margin: 0 0 0 $padding-sm;
    font-size: 1.25 * $text-size;
    font-weight: 800;
    color: $color-text;
  }
}

section.layout-one ul li {
  width: 100% !important;
  padding: 0 0 $padding !important;
}

section.layout-three ul li {
  width: 33% !important;
  padding: 0 $padding $padding 0 !important;
}

section.align-left ul {
  display: flex;
  justify-content: flex-start;
}

section.align-center ul {
  display: flex;
  justify-content: center;
}

section.align-right ul {
  display: flex;
  justify-content: flex-end;
}
