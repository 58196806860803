@import '../../../scss/vars.scss';
@import '../../../scss/mixins.scss';

.splitter {
  display: flex;
  padding: 0 $padding;
}

.loading {
  width: 50%;
}

.header {
  display: none !important;
}

.siteLink {
  display: none;
}

@media (max-width: 991px) {
  .splitter {
    display: flex;
    padding: 0;
  }

  .siteLink {
    @include position(sticky);
    background: white;
    border-bottom: $border;
    display:flex;
    // justify-content: center;
    padding: 12px 20px;
    top: -1px;
    z-index: 2;

    .views {
      width: fit-content;
      border-right: 1px solid #ddd;
      margin-right: 15px;
      display: flex;
      align-items: center;
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h6 {
        width: 45vw;
      }
    }

    h6 {
      font-size: $text-size;
      font-weight: 700;
      max-width: 100%;
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 15px;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
  
      a {
        color: $color-link;
        font-weight: 400;
        text-decoration: underline;
        white-space: nowrap;

        &:hover {
          color: $color-link;
          opacity: .75;
        }
      }
    }
  }
}
