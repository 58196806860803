@import '../../scss/vars.scss';

.container {
  display: inline-flex;

  & > *:not(:first-child) {
    margin-left: $padding-xs;
  }
}

.switch {
  background-color: rgba(0, 0, 0, 0.25);
}