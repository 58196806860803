@import '../scss/vars.scss';

.choose-image-modal {
  .ant-modal-content {
    width: 520px !important;
    height: 600px !important;
    padding: 0 !important;

    .container.upload {
      .ant-tabs-content {
        margin-top: 0 !important;
        height: 100%;
      }
    }

    .ant-modal-body, .container {
      .ant-tabs {
        max-height: 500px;

        .ant-tabs-content-holder {
          height: 450px;
          overflow: auto;
        }

        &.with-border {
          .ant-tabs-content-holder {
            border-top: 1px solid $color-share-grey;
            border-bottom: 1px solid $color-share-grey;
          }
        }

        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0 0 0 50px;
        }

        .ant-tabs-nav {
          padding: 0 $padding-sm $padding-sm $padding-sm;
          margin: 0 !important;

          &:before {
            border-bottom: 0;
          }

          .ant-tabs-nav-wrap {
            justify-content: center;

            .ant-tabs-nav-list {
              font-weight: bold;
            }
          }
        }

        .ant-upload-drag {
          border: 2px dashed $color-btn-border-grey;
          border-radius: $radius-xlarge;
        }

        .ant-upload-drag-icon {
          svg {
            font-size: 120px;
            color: $color-btn-border-grey;
          }
        }

        .ant-tabs-content {
          margin-top: 5px;
          min-height: 335px;
        }

        .ant-upload-text {
          margin-top: $padding-sm;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          > span {
            color: $color-btn-dark-grey;
          }

          button {
            margin-top: $padding-sm;
            width: 134px;
            height: 40px;
            background: $color-btn-dark-grey 0 0 no-repeat padding-box;
            color: $color-white;
            border: 0;

            &:hover {
              opacity: 0.75;
            }
          }
        }

        .upload-tab {
          height: 100%;
          padding: 0 $padding-sm;
        }

        .image-tab {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .member-image-gallery {
          display: flex;
          justify-content: center;
          align-items: center;

          div.selectable-image {
            flex: 1;
            cursor: pointer;
            padding: $padding-xxs;
          }
        }
      }
    }
  }

  footer {
    margin-top: $padding-xs !important;
    padding: $padding-sm !important;

    button {
      width: 134px;
      height: 40px;

      &:disabled {
        background-color: $color-btn-disabled !important;
        border: 0 !important;

        &:hover {
          border: 1px solid #cfcfcf !important;
        }
      }
    }
  }
}
