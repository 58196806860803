@import '../../scss/vars.scss';

footer.main {
  padding: $padding 0;

  .address {
    p {
      font-size: .9em;
      text-align: center;
      margin: 0;
      line-height: 1.35em;
    }
  }

  .mhm {
    text-align: center;
    margin-top: $padding-sm;
    a {
      color: $color-powered-by;
      font-size: 14px;
      letter-spacing: -.5px;
      font-weight: 100;

      span {
        font-weight: 1000;
      }

      &:hover {
        text-decoration: underline;
        text-decoration-color: $color-powered-by;
      }
    }
  }
}
