@import '../../../scss/vars.scss';

.settings-announcements {
  header {
    flex-direction: column;
  }

  section {
    div {
      .sp {
        margin-left: $padding-sm;
      }

      &.banner {
        margin-top: $padding-lg;

        &.text {
          display: flex;
          flex-direction: column;

          h3 {
            display: flex;

            span {
              margin-left: auto;
              font-size: $text-size;
              color: $color-light-grey;
            }
          }
        }

        &.schedule {
          flex-direction: column;

          &.active {
            font-weight: 800;
          }

          div.status {
            cursor: pointer;
            display: flex;
            align-items: center;

            svg {
              font-size: $text-size-md;
              color: $color-black;
            }
          }
          
          .header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: $padding-sm;

            span {
              width: 100%;
              font-weight: 800;
            }

            .close-btn {
              margin-left: auto;
              padding: $padding-xs;

              svg {
                font-size: $text-size-md;
              }
            }
          }

          div.content {
            display: flex;
            flex-direction: row;
            border-left: none;
            align-items: center;

            div.separator {
              text-align: center;
            }
          }
        }

        &.design {
          display: flex;
          flex-direction: row;

          div.design-color-picker:last-child {
            margin-left: $padding-sm;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .settings-announcements {
    section {
      div.banner.design {
        display: block;

        div.design-color-picker:last-child {
          margin-left: 0;
          margin-top: $padding-sm;
        }
      }
    }
  }
}
