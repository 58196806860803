@import "../../../scss/vars.scss";

.sizes {
  display: flex;
  flex-direction: column;
  margin-top: $padding-sm;

  ul {
    flex-direction: column;
    display: flex; 
    gap: $padding-sm;

    li {
      display: flex;
      flex-direction: row;
      flex: 2;

      .sizeInput {
        border-bottom-left-radius: 6px!important;
        border-top-left-radius: 6px!important;
        margin-right: $padding-sm;
      }

      .priceInput {
        width: auto;
        border-bottom-right-radius: 6px!important;
        border-top-right-radius: 6px!important;
      }

      .singleSizeInput {
        display: flex;
        width: 100%;
        gap: 15px;
        align-items: center;
      }
    }
  }
}

.remove {
  margin-left: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1024px) {
  .sizes {
    display: flex;
    flex-direction: column;
    margin-top: $padding-sm;

    ul {
      flex-direction: column;
      display: flex; 
      
      li {
        display: flex;
        margin-bottom: $padding-sm;
        margin-right: $padding-sm;
        width: 100%;
      }
    }
  }
}
