@import '../scss/vars.scss';

.pc {
  .c {
    &.l {
      margin-right: 3px;
    }
  }
}

.price {
  border-radius: $border-radius;

  & > span > span {
    span {
      border-radius: $border-radius 0 0 $border-radius;
    }
    input {
      border-radius: 0 $border-radius $border-radius 0 !important;
    }
  }
}