@import "vars";

.ant-tabs.enhanced.fixed {
  .ant-tabs-nav {
    width: $dashboard-aside-width;
    position: fixed;
    left: 0;
    bottom: 0;
    top: $header-height;
    border-right: $border;
  }

  .ant-tabs-content-holder {
    margin-left: 0;
    border: 0;
  }

  .ant-tabs-tabpane-active {
    padding: 0 !important;
  }

  .ant-tabs-tab {
    margin-top: $padding-xs;
  }
}

.ant-tabs.enhanced {
  .ant-tabs-bar {
    background: #FFF;
    border: 1px solid rgb(230, 233, 237);
    border-radius: $radius-large;

    &.ant-tabs-left-bar {
      width: $dashboard-secondary-sidebar-width;
      margin-top: $padding;
      margin-left: $padding;
    }

    .ant-tabs-tab {
      font-size: $text-size - 1px;
      margin: 0;
      padding: 1.5 * $padding-xs $padding-sm;
      color: rgb(136, 148, 171);
      display: flex;
      align-items: center;

      &.ant-tabs-tab-active {
        font-weight: bold;
        background: #FFF;
        color: $color-text;
      }

      &:first-child {
        border-radius: $radius-large $radius-large 0 0;
      }

      &:last-child {
        border-radius: 0 0 $radius-large $radius-large;
      }
    }

    .ant-tabs-nav-container {
      margin: 0;
    }

    .ant-tabs-ink-bar {
      display: none !important;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .ant-tabs-content {
    border: 0;
    width: calc(70% - 24px);
    max-width: $container-width-compact;
    padding: $padding;
  }

  &.compact {
    .ant-tabs-bar {
      &.ant-tabs-left-bar {
        width: auto;
      }

      .ant-tabs-tab {
        span:last-child {
          display: none;
        }

        .anticon {
          margin: 0;
        }
      }
    }
  }

  &.xl {
    .ant-tabs-content {
      max-width: 1150px;
    }
  }
}


@media (max-width: 991px) {
  .ant-tabs.enhanced.fixed .ant-tabs-content-holder {
    margin-left: $dashboard-aside-width;
  }
  .ant-tabs.enhanced .ant-tabs-content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .ant-tabs.enhanced {
    .ant-tabs-bar {
      &.ant-tabs-left-bar {
        width: auto;
      }

      .ant-tabs-tab {
        span:last-child {
          display: none;
        }

        .anticon {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .ant-tabs.enhanced {
    .ant-tabs {
      .ant-tabs-left-content {
        padding-left: $padding-sm;
      }
    }
  }
}
