@import '../../../scss/vars.scss';

.emp-itm-mdl {
  .ant-modal-body {
    h2 {
      font-weight: 700;
      margin: 0 0 $padding;
    }
    p {
      margin: $padding 0 0;
      font-size: 16px;
      line-height: 1.25em;
    }
  }
  .ant-modal-footer {
    border: 0;
    button {
      text-transform: capitalize;
    }
  }
}
