@import '../../../scss/vars.scss';

.drag-icon {
  flex: 0 0 auto;
  height: 12px;
  width: 12px;
  touch-action: none !important;

  &.large {
    height: 16px;
    width: 16px;
  }
}
