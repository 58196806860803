@import '../../scss/vars.scss';

.share-drop .overlay {
    display: none;
}

.dropContent {
    background: white;
    box-shadow: 0 0 3px #00000029;
    padding: 25px 0;
    border-radius: 4px;
    width: 440px;

    h4 {
        font-weight: bold;
        padding: 0 40px;
        margin-top: 0;

        &.biz-links {
            margin-top: 40px;
        }
    }

    >p.biz-links {
        line-height: 1.2em;
        margin-top: -5px;
        padding: 0 40px 10px;
    }

    ul {
        display: flex;
        justify-content: flex-start;
        margin: 10px 20px 20px;

        li {
            width: 80px;
            margin: 0 10px;
            display: flex;
            justify-content: center;
            text-align: center;

            .icon-container {
                display: block;
                margin: auto;
                margin-bottom: 5px;
                width: 48px;
                height: 48px;
                border: 2px solid #eee;
                border-radius: 50%;
                cursor: pointer;

                >span,
                >img {
                    width: 44px;
                    height: 44px;

                    >svg {
                        width: 26px;
                        height: 44px;
                        fill: #333;
                    }
                }

                &.instagram {
                    img {
                        width: 28px;
                        height: 28px;
                        margin-top: 8px;
                    }
                }

                &:hover {
                    border-color: $color-primary;
                }
            }

            .share-option {
                color: #333;
                line-height: 1.2em;
            }
        }
    }

    &.sub-menu {
        padding: 25px 30px;

        .back-btn {
            display: inline-block;
            cursor: pointer;

            >span>svg {
                fill: $color-text;
            }
        }

        .mode-title {
            display: inline-block;
            padding: 0 10px;
        }

        .instructions {
            margin-top: 1em;
        }

        .qr-code-block {
            display: flex;
            margin-top: 10px;

            .qr-code {
                margin-right: 20px;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 0 3px #00000029;
                }
            }
        }

        .link-block {
            border: 1px solid $color-share-grey;
            border-radius: 5px;
            height: 45px;
            display: flex;
            align-items: center;
            margin-top: 25px;
            position: relative;
    
            span.anticon-link {
                margin: 0 5px 0 12px;
    
                svg {
                    fill: $color-text;
                    width: 18px;
                    height: 18px;
                }
            }
    
            .link-input {
                width: 275px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: bottom;
                padding: 11px 5px;
            }
    
            .link-btn {
                position: absolute;
                right: 0;
                width: 65px;
                text-align: center;
                padding: 11px 0;
                color: $color-text;
                border-left: 1px solid $color-share-grey;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .btn-block {
            margin-top: 9px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-share-grey;
            border-radius: 5px;
            cursor: pointer;

            >span,
            >img {
                display: inline-block;
                height: 20px;

                >svg {
                    width: 20px;
                    height: 45px;
                    fill: $color-text;
                }
            }

            .btn-label {
                margin-left: 10px;
            }
        }
    }
}

@media (max-width: 768px) {
    .share-drop.ant-dropdown {
        animation-duration: 0s !important;

        .overlay {
            display: block;
            height: 100vh;
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            background-color: #00000073;
        }
    }
    .dropContent {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        padding-bottom: 85px !important;

        @media (max-width: 500px) {
            ul li {
                width: calc((100% / 4) - 20px);
            }
        }
    }
}