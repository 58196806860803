@import "../../../scss/vars.scss";

.copy-to-location-modal.ant-modal:not(.details-item-modal):not(.business-hours-modal) {
  height: 230px;

  .ant-modal-content {
    padding: 30px 60px;
    height: 100%;

    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: bold;
        margin: 0;
      }

      .ant-select {
        width: 100%;
        height: 40px;
        text-align: left;

        .ant-select-selector {
          height: 100%;

          .ant-select-selection-placeholder,
          .ant-select-selection-item {
            line-height: 40px;
            font-size: 15px;
          }

          .ant-select-selection-placeholder {
            color: $color-text;
          }
        }

        .ant-select-dropdown {
          padding: 0;
          border-radius: 6px;

          .ant-select-item {
            display: flex;
            align-items: center;
            height: 60px;
            padding: 9px 15px;
            font-size: 15px;
            line-height: 21px;

            &:first-child {
              height: 44px;
              border-bottom: 1px solid $color-btn-border-grey;
              color: $color-grey;
              font-weight: bold;
            }
          }
        }
      }

      .btn-container {
        .ant-btn {
          width: 93px;
          line-height: 36px;
        }
      }
    }
  
    .ant-modal-footer {
      display: none;
    }
  }
}