@import '../../scss/vars.scss';

.phone-input-container {
  position: relative;

  .input-with-addon {
    width: calc(100% - 40px);
    margin-left: 40px;
    z-index: 1;
    position: absolute;
    left: 0;
    border-radius: 0 6px 6px 0 !important;
  }
}
