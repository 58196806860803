@import '../../../scss/vars.scss';

.item {
  .item-actions {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: flex-end;

    .ant-btn-link {
      padding: 2px;
      margin: 0 10px 0 13px;
      height: 24px;
      border-radius: 4px;

      &:hover {
        background-color: $color-btn-light-grey;
      }
    }

    .anticon {
      width: 18px;
      height: 18px;

      svg {
       color: $color-text;
       width: 100%;
       height: 100%;
      }
    }

    >.anticon {
      width: 24px;
      height: 24px;
      padding: 3px;
      border-radius: 4px;

      &:hover {
        background-color: $color-btn-light-grey;
      }
    }
  }

  .name-icon-actions {
    display: flex;
    gap: 15px;

    >div:first-child {
      flex: 1;

      >input {
        height: 40px;
      }

      .current-icons {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        align-items: center;

        .current-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ddd;
          background: white;
          border-radius: 6px;
          width: 34px;
          height: 32px;
          padding: 3px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }

          &.active {
            border-color: $color-primary;
          }
    
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  textarea::placeholder {
    font-size: 15px;
  }
}

.ant-collapse-content-box {
  padding: 0 !important;
}

div.item-active-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $padding-sm;

  label {
    font-weight: normal;
    margin-top: 0;
  }
  button {
    margin-left: auto;
  }
}

@media(max-width: 425px) {
  .sortable-menu-item-container div.uploader {
    height: 50%;
  }
}
