@import '../../scss/vars.scss';

.am-pm {
  width: 55px !important;
  margin-left: 6px;

  .ant-select-selector {
    padding: 0 !important;
  }

  span.ant-select-arrow {
    svg {
      font-size: 10px;
    }
    right: 20px;
  }
}

.invisible {
  visibility: hidden;
}
