@import '../../scss/vars.scss';

.stars {
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    fill: $color-text;
    margin: 0 1px;
  }

  h6 {
    line-height: 1em;
    font-size: $text-size;
    font-weight: 400;
    margin: 0 0 0 $padding-sm / 2;
    color: $color-text;
    span {
      font-weight: 800;
    }
  }
}

.large {
  svg {
    width: 22px;
    height: 22px;
  }
}

.small {
  svg {
    width: 13px;
    height: 13px;
  }

  h6 {
    font-size: $text-size - 2px;
  }
}

.error {
  svg {
    fill: $err-color;
  }
}
