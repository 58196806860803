@import '../../scss/vars.scss';

.business-hours-modal {
  width: 400px !important;

  .ant-modal-body {
    font-size: 16px;
  }

  header {
    text-align: center;
    h2 {
      font-weight: bold;
    }
  }

  section {
    padding: $padding-md 0;
    max-width: 300px;
    margin: auto;

    div.entry {
      display: flex;
      padding: 0 $padding-sm;
      margin-top: $padding-xs;

      &.act {
        font-weight: bold;
      }

      span.day {
        text-transform: capitalize;
      }

      div.hours {
        margin-left: auto;

        label {
          display: block;
          text-align: right;
        }
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}
