@import "../../scss/vars.scss";

.revisions {
  ul {
    li {
      display: flex;
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid #eee;
      opacity: .95;
      min-height: 39px;

      span.details {
        margin: 0;
        font-weight: 100;
        font-size: 1em;

        &.selected {
          font-weight: 800;
        }
      }
      div.actions {
        margin-left: auto;

        div.loader {
          height: 40px !important;
          right: 13px;

          div {
            top: 15px;
          }
        }
      }
    }
  }
}
