@import '../../../scss/vars.scss';


.container {
  >* {
    margin-top: $padding-sm;
  }
}

.entryV2 {
  display: flex;

  div:first-child {
    flex: 2;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
  }

  div:last-child {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  div>span {
    display: block;
    padding-left: $padding-xs;

    >span {
      margin-left: 4px;
      font-style: italic;
      font-size: 12px;
    }
  }

  ul {
    margin-top: $padding-xs;
    padding-left: 26px;
    list-style-type: circle;
  }
}