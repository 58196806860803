@import '../scss/vars.scss';

.auth-form {
  .ant-btn-link {
    text-transform: none !important;
  }

  h1 {
    font-size: 1.5 * $text-size;
    font-weight: bold;
    margin-bottom: $padding;
    text-align: center;
  }

  .ant-input-suffix {
    display: flex;
    align-items: center;
  }

  .ant-btn-primary {
    margin: 0 auto;
  }

  .reset {
    display: block;
    text-align: center;
    color: $color-grey;
    margin-top: $padding;
  }

  .btn-ctr {
    display: flex;
    justify-content: center;
  }

  &.lg {
    .ant-btn-primary {
      width: 100%;
      height: $input-xl-height;
    }

    input {
      height: $input-xl-height;
      padding: $input-lg-padding;
    }

    .ant-input-password {
      padding: 0 $padding-sm;
    }

    input[type='password'] {
      height: $input-xl-height;

      .ant-input-suffix {
        margin-top: 6px;
      }
    }
  }
}

@media (max-width: 425px) {
  .auth-form {
  }
}
