@import '../../scss/vars.scss';

.picker {
  max-width: 62px;
  text-align: center;

  &.err {
    border-color: $err-color;
  }

  span.ant-input-group-addon {
    padding: 0;
    border: 0;
    background-color: initial;
    right: 20%;
    z-index: 1;
  }

  input.ant-input {
    padding: 3px 3px 3px 5px;
  }
}