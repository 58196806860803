@import "../scss/vars.scss";

.uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 82px;
  height: 82px;

  div.loader {
    flex: 0;
  }

  &:not(.with-drop) {
    div.loader.small > div {
      top: 60px;
    }
  }

  .ant-upload-picture-card-wrapper {
    width: 100%;
    height: 100%;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    border: $border;
    background: #fff;

    .ant-upload {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    button {
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      >div {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-light-grey;
        font-weight: bold;

        >span.anticon {
          width: 28px;
          height: 24px;

          >svg {
            color: $color-light-grey;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .preview {
    width: 100%;
    max-height: $dashboard-preview-size;
    background-size: cover;
    background-position: center center;
    min-height: $dashboard-preview-size;
    border-radius: $border-radius;
    border: 1px solid #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey;
    font-size: $text-size - 3px;
    font-weight: 100;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .ant-dropdown-trigger {
    position: absolute;
    top: 4px;
    right: 4px;
    background: rgba(255, 255, 255, .75);
    border-radius: 50%;
    padding: 0;
    width: 28px;
    height: 28px;

    svg {
      fill: $color-text;
    }
  }
}

.ant-dropdown-menu.upload-img-menu {
  width: 202px;
  min-width: 0;

  li {
    height: 45px;
    padding: 9px 15px;

    >span {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;

      svg {
        width: 20px;
        height: 20px;
        color: $color-text;
      }

      .anticon {
        width: 20px;
        height: 20px;

        svg {
          width: 100%;
          height: 100%;
          color: $color-text;
        }
      }
    }
  }

  .ant-upload.ant-upload-select {
    display: block;
    width: 100%;
  }
}
