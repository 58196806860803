@import '../../../scss/vars.scss';

.expand-icon {
  font-size: 10px;

  &.open {
    transform: rotate(180deg);
    transition: transform 0.24s, -webkit-transform 0.24s;
  }

  &.closed {
    transform: rotate(0deg);
    transition: transform 0.24s, -webkit-transform 0.24s;
  }
}

.ant-collapse-header {
  display: none;
}

.section-container {
  margin-left: $padding-sm;

  .section-selected>button {
    font-weight: bold;
  }
}