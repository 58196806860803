@import '../../../scss/vars.scss';

.container {
  margin-top: $padding-sm;
}

.column {
  flex: 1;
}

.line {
  display: flex;
  margin-top: $padding-sm;

  div:first-child button {
    width: 75% !important;
  }

  button {
    width: 50% !important;
  }
}