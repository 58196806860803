@import '../../scss/vars.scss';

.menu-preview {
  border: 5px solid #222;
  border-radius: 14px;
  width: calc(34vh);
  height: calc(70vh);
  min-width: 250px;
  max-width: 375px;
  max-height: 812px;
  overflow: hidden;
  position: relative;

  & > div,
  iframe{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: content-box;
  }

  &.small {
    min-width: 180px;
    width: 180px;
    height: 350px;
  }
}
