@import '../../../scss/vars.scss';

.biz-day {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $padding-sm;
  padding: 0 0 $padding-sm;

  .sw {
    margin-right: $padding-sm;
    min-width: 150px;

    & > label {
      flex: 1;
      margin-left: $padding-xs;
      white-space: nowrap;
      user-select: none;
      min-width: 75px;
      display: inline-block;
    }
  }

  .interval {
    display: flex;
    flex-direction: column;
  }

  .status {
    display: inline-block;
    margin-left: $padding-xs;

    button {
      margin-left: $padding-xs;
    }

    label {
      letter-spacing: 1px;
      color: $color-grey;
      user-select: none;
    }
  }
}

@media (max-width: 640px) {
  .biz-day {
    flex-direction: column;
    flex-wrap: nowrap;
    border-bottom: $light-border;
    margin: 0;
    padding: $padding-xs 0;

    .sw {
      margin: 0;
      display: flex;

      button {
        margin-left: auto;
      }
    }

    .interval {
      margin-top: $padding;
    }

    .container-interval {
      input {
        border-radius: $border-radius !important;
      }

      .separator {
        display: none;
      }

      .am-pm {
        padding: 0;
        &.ant-select-show-arrow {
          width: 45px;
        }
      }
    }

    &:last-child {
      border: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
@media (max-width: 320px) {
  .biz-day {
    .container-interval {
      .ant-select-arrow {
        right: 5px;
      }
    }
  }
}

