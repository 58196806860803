@import '../../../scss/vars.scss';

.settings-seo {
  .entry {
    display: flex;
    align-items: flex-end;

    &.error {
      align-items: center;
    }

    > div {
      flex: 4;
      margin-right: $padding;
    }

    > button {
      flex: 1;
      margin-left: auto;
    }
  }
}
