@import '../../../scss/vars.scss';

.payments {
  img {
    height: 50px;
    position: relative;
    left: -10px;
  }

  p {
    font-size: $text-size + 1px;
    color: $color-text;
    margin: $padding-sm 0 0;
    line-height: 1.3em;

    a {
      color: $color-text;
    }
  }

  div {
    margin-top: $padding-sm;
  }

  button {
    margin-top: $padding-xs;
  }
}

.badged {
  display: flex;

  a {
    display: inline-block;
    height: 20px;
    margin-left: auto;
    img {
      height: 100%;
    }
  }
}

